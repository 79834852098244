import { RadarEntry, MovedState } from '@backstage-community/plugin-tech-radar-common';

export const wpeTechRadarEntries: RadarEntry[] = [
  {
    timeline: [
      {
        moved: MovedState.Down,
        description: `Gemfury is still allowed (Adopt) in specific circumstances, but Google
Artifact Registry is preferred for new use cases where it is capable. Gemfury is on Hold with the
exceptions outlined in the entry.`,
        ringId: 'Hold',
        date: new Date('2023-12-13'),
      },
      {
        moved: MovedState.NoChange,
        ringId: 'Adopt',
        date: new Date('2022-08-05'),
      },
    ],
    url: '#',
    key: 'gemfury',
    id: 'gemfury',
    title: 'Artifacts: Gemfury',
    quadrant: 'languages-and-frameworks',
    description: `Use Google Artifact Registry rather than Gemfury for artifact management of internal packages, where possible.
      See its entry in the radar for specifics.
      \nUse Gemfury for for languages not yet supported by Google Artifact Registry.
      For example, Ruby packages are not yet supported.
      \nGemfury remains in Adopt where it is already implemented; there is no requirement to move off Gemfury,
      though there is a preference for Google Artifact Registry for new use cases.
      When using Gemfury, there is Jenkins shared library tooling available to publish artifacts from a deploy pipeline.
      Usage for public React/JS component distribution is moving to npm.`,
  },
  {
    timeline: [
      {
        moved: MovedState.Down,
        description: `Extended what is Prohibited beyond new container registries to include
          existing registries and containers within them based on Google announcements.
          Communicate Catalyst plan and final dates.`,
        ringId: 'Prohibited',
        date: new Date('2024-04-09'),
      },
      {
        moved: MovedState.Down,
        description: "shifted from hold to prohibited, set final date",
        ringId: 'Prohibited',
        date: new Date('2023-05-17'),
      },
      {
        moved: MovedState.Down,
        ringId: 'Hold',
        date: new Date('2022-08-05'),
      },
    ],
    url: '#',
    key: 'google-container-registry',
    id: 'google-container-registry',
    title: 'Artifacts: Google Container Registry',
    quadrant: 'languages-and-frameworks',
    description: `Google Artifact Registry is the primary container image registry. Google
Container Registry (GCR) is also supported, but deprecated. The use of other WP Engine-managed
registries must be approved by Security. The WP Engine Containerized Workloads Policy says "any
new containerized projects in Google Cloud must utilize Google Artifact Registry for managing
containers and other artifacts."

# Google Container Registry is deprecated by Google

**As of
[May 15, 2024](https://cloud.google.com/container-registry/docs/deprecations/container-registry-deprecation),
no new GCR instances can be deployed in new projects. Starting
[May, 2025](https://drive.google.com/file/d/1_ioI5XyzhiOfG7Aab94wF4kpc_RRbXDC/view?usp=drive_link),
GCR functionality is being phased out. Without action, pushes and pulls for GCR will fail. All
teams must be off Google Container Registry before then.**

[Google notifications](https://drive.google.com/file/d/1PTDuIn4miTzbY408KilLzUeanNgzf5ou/view)
provide the following specific timeline:

* Jan 8 2024 - all new gcr.io registries will be hosted on Artifact Registry by default
  ([reference](https://cloud.google.com/container-registry/docs/deprecations/container-registry-deprecation))
* May 15, 2024 - all new projects and projects without GCR will only support GAR
  ([reference](https://cloud.google.com/container-registry/docs/deprecations/container-registry-deprecation))
* Mar 18, 2025 - GCR replaced by Google Artifact Registry; no pushes allowed to GCR
  ([reference](https://drive.google.com/file/d/1_ioI5XyzhiOfG7Aab94wF4kpc_RRbXDC/view?usp=drive_link))
* Apr 22, 2025 - GCR resident images no longer available
  ([reference](https://drive.google.com/file/d/1_ioI5XyzhiOfG7Aab94wF4kpc_RRbXDC/view?usp=drive_link))
* May 22, 2025 - All requests to gcr.io will be handled exclusively by GAR
  ([reference](https://drive.google.com/file/d/1_ioI5XyzhiOfG7Aab94wF4kpc_RRbXDC/view?usp=drive_link)}

# Plan for \`wpe-cr\`, \`wpe-cr-dev\`, and Catalyst artifacts

Catalyst has published
[its plan](https://docs.google.com/document/d/1S7H_f_H0yPGh1RaPEJ2WUCy__9kZLjmAozvklDmk79E/edit)
to handle migration of docker images in Google Container Registry to Google Artifact Registry for
images managed by the Catalyst team, as well as images used for applications running on SDP.

This plan also includes migration for the GCR instances in
[wpe-cr](https://console.cloud.google.com/gcr/images/wpe-cr?referrer=search&project=wpe-cr) and
[wpe-cr-dev](https://console.cloud.google.com/gcr/images/wpe-cr-dev?project=wpe-cr-dev), but not
any other GCR instances in other projects.

Read the plan for specifics. But in summary, the Catalyst team will be responsible for:

* Migrating all images owned by the Catalyst team to GAR.
* Updating all SDP stakeholder applications to only use images from the existing
  [us-docker.pkg.dev/wpe-art/sdp-images](https://console.cloud.google.com/artifacts/docker/wpe-art/us/sdp-images)
  GAR registry. This includes both application runtime images and utility images supplied by the
  Catalyst team. Where needed, teams will need to approve Catalyst PRs that affect their GitHub
  repos.
* Communicating a plan and a timeline for the migration of SDP applications to
  [us-docker.pkg.dev/wpe-art/sdp-images](https://console.cloud.google.com/artifacts/docker/wpe-art/us/sdp-images)
  to all SDP stakeholders.

With respect to images currently in the
[wpe-cr](https://console.cloud.google.com/gcr/images/wpe-cr?referrer=search&project=wpe-cr) and
[wpe-cr-dev](https://console.cloud.google.com/gcr/images/wpe-cr-dev?project=wpe-cr-dev) projects
which are not related to SDP, the Catalyst team will be responsible for:

* Migrating the GCR registries in the
  [wpe-cr](https://console.cloud.google.com/gcr/images/wpe-cr?referrer=search&project=wpe-cr) and
  [wpe-cr-dev](https://console.cloud.google.com/gcr/images/wpe-cr-dev?project=wpe-cr-dev) projects
  to GAR registries in those projects, such that existing docker image names such as
  \`gcr.io/wpe-cr/argo-deploys:1.1.5\` will pull and push from the new Artifact Registry. In other
  words, the image URLs will remain the same but the images will be moved from a GCR instance to a
  GAR instance. This should ensure the Google end of life does not impact any pipelines that depend
  on them.
* Communicating a plan and a timeline for the migration of the GCR registries in the
  [wpe-cr](https://console.cloud.google.com/gcr/images/wpe-cr?referrer=search&project=wpe-cr) and
  [wpe-cr-dev](https://console.cloud.google.com/gcr/images/wpe-cr-dev?project=wpe-cr-dev), projects
  to P&E.
* Providing documentation on how to set up new docker image registries within the \`wpe-art\` and
  \`wpe-art-sandbox\` projects for teams that want to establish their own domain-specific
  registries.

The Catalyst team will ***NOT*** be responsible for:

* Migrating images which are not owned by the Catalyst team in the
  [cloud-builders](https://github.com/wpengine/cloud-builders) and
  [third-party-images](https://github.com/wpengine/third-party-images) GitHub repos to
  \`us-docker.pkg.dev/wpe-art/sdp-images\`.
* Finding or updating consumers outside of SDP who are using Catalyst-owned images from
  [cloud-builders](https://github.com/wpengine/cloud-builders) and
  [third-party-images](https://github.com/wpengine/third-party-images).
* Migrating GCR instances in any GCP projects other than
  [wpe-cr](https://console.cloud.google.com/gcr/images/wpe-cr?referrer=search&project=wpe-cr) and
  [wpe-cr-dev](https://console.cloud.google.com/gcr/images/wpe-cr-dev?project=wpe-cr-dev).

# Other Google Container Registry projects and instances

As of March 22, 2024 the following projects contain GCR instances. As stated above, these GCR
instances will not be migrated by the Catalyst team. Each of these cases should be reviewed by
their respective owners.

\`\`\`plaintext
data-engineering-develop
metrics-platform-development
metrics-platform-production
perf-web-vitals-development
prodeng-development
prodeng-privatebin
prodeng-stackstorm
wp-engine-corporate
wp-engine-data-raw
wp-engine-development
wp-engine-headless-build
wp-engine-headless-dev
wp-engine-headless-production
wp-engine-headless-scratch
wp-engine-headless-staging
wp-engine-performance-tools
wp-engine-portal-tools
wp-engine-production
wp-engine-vendor-config
wpe-gs-registration-dev
wpe-pod-provisioner-dev
wpe-pod-provisioner-prd
wpe-pod-provisioner-stg
\`\`\`

Due to the author’s IAM permissions, this list is only inclusive of projects under the Engineering
folder in the \`wpengine.com\` Organization in GCP. There may be other instances in the
\`wpengine.com\` Organization or in the \`flywheel\` or \`deliciousbrains\` organizations. The
documentation
[here](https://cloud.google.com/artifact-registry/docs/transition/check-gcr-usage#organization)
explains how to check for GCR instances in other folders or organizations.

The Google document
[Transition from Container Registry](https://cloud.google.com/artifact-registry/docs/transition/transition-from-gcr)
provides options to help the responsible owners form their strategy. While Catalyst will not be
responsible for these migrations, they can consult given their experience with the scope outlined above.

# Movement towards more granular registries

One of the benefits of Google Artifact Registry over GCR is its ability to support multiple
registries within a specific project. This allows segmentation of registries by domain, use case,
and secuirity, and these new capabilities help mature best practice. Catalyst provides the central
[wpe-cr](https://console.cloud.google.com/gcr/images/wpe-cr?referrer=search&project=wpe-cr) and
[wpe-cr-dev](https://console.cloud.google.com/gcr/images/wpe-cr-dev?project=wpe-cr-dev) projects
to ease overall management and provide governance for the use of registries in WP Engine.
However, we encourage separation of registries by use case or domain. This allows for more
fine-grained access control. For example, it reduces the risk of a security incident in one domain
impacting another.

Consider this as part of your migration strategy. For example, Catalyst is migrating its
SDP-related images from
[wpe-cr](https://console.cloud.google.com/gcr/images/wpe-cr?referrer=search&project=wpe-cr) and
[wpe-cr-dev](https://console.cloud.google.com/gcr/images/wpe-cr-dev?project=wpe-cr-dev) to
multiple distinct GAR registries. It is not merely relying on the general migration of
[wpe-cr](https://console.cloud.google.com/gcr/images/wpe-cr?referrer=search&project=wpe-cr) and
[wpe-cr-dev](https://console.cloud.google.com/gcr/images/wpe-cr-dev?project=wpe-cr-dev) to a
generic GAR instance. Beyond just the SDP domain, this will likely result in separate registries
for SDP application images, cloud builders, etc.

The specific plans for registries in other projects, or for containers specific to your use
cases, are left to teams. But this is a consideration for those plans. As stated above, Catalyst
will provide documentation on how to set up docker image registries within the \`wpe-art\` and
\`wpe-art-sandbox\` projects for teams that want to establish their own domain-specific image
registries.

# Resources

* [RFC: Migrating Container Registry to Artifact Registry](https://docs.google.com/document/d/1S7H_f_H0yPGh1RaPEJ2WUCy__9kZLjmAozvklDmk79E/edit)
* [Google: Container Registry deprecation](https://cloud.google.com/container-registry/docs/deprecations/container-registry-deprecation)
* [email from Google, March 18, 2024](https://drive.google.com/file/d/1_ioI5XyzhiOfG7Aab94wF4kpc_RRbXDC/view?usp=drive_link)
* [Transition from Container Registry](https://cloud.google.com/artifact-registry/docs/transition/transition-from-gcr)
* [Containerized Workloads Policy](https://drive.google.com/file/d/1Zvw2K8l_ZM0uRe2iskGa1pBRCoFrq7gG/view)
* [Containerized Workloads Policy - Implementation Guidelines](https://drive.google.com/file/d/1Xw2fk7fwFLOrQElyjZ3FzGdMnx0zttqs/view)
* [Catalyst: Working with Google docker registries](https://backstage.wpesvc.net/docs/default/system/catalyst-software-delivery-platform/guides/tools-and-support/local-dev-tools/#working-with-google-docker-registries)
* [Catalyst: Application Container image](https://backstage.wpesvc.net/docs/default/system/catalyst-software-delivery-platform/#container-image)`,
  },
  {
    timeline: [
      {
        moved: MovedState.Up,
        ringId: 'Adopt',
        date: new Date('2022-08-05'),
      },
    ],
    url: '#',
    key: 'google-artifact-registry',
    id: 'google-artifact-registry',
    title: 'Artifacts: Google Artifact Registry',
    quadrant: 'languages-and-frameworks',
    description: `Google Artifact Registry is the primary container image registry.
      Google Container Registry is also supported, but deprecated.
      The use of other WP Engine-managed repositories must be approved by Security.
      \n**Note: Google Container Registry is deprecated by Google, and will be
      [discontinued starting May 15, 2024](https://cloud.google.com/container-registry/docs/deprecations/container-registry-deprecation).
      See its entry for specific guidance.**
      \n* The WP Engine Containerized Workloads Policy says "any new containerized projects in Google Cloud must utilize Google Artifact
      Registry for managing containers and other artifacts."
      \n* Catalyst SDP supports docker images from both Google Container Registry and Google Artifact Registry. The
      [Catalyst release notes from February, 2023](https://backstage.wpesvc.net/docs/default/system/catalyst-software-delivery-platform/release-notes/#02-09-23)
      state "From this point forward, all new SDP applications will push their application Docker images to Google Artifact Registry."
      \nSee:
      \n* [Containerized Workloads Policy](https://drive.google.com/file/d/1Zvw2K8l_ZM0uRe2iskGa1pBRCoFrq7gG/view)
      \n* [Containerized Workloads Policy - Implementation Guidelines](https://drive.google.com/file/d/1Xw2fk7fwFLOrQElyjZ3FzGdMnx0zttqs/view)
      \n* [Catalyst: Working with Google docker registries](https://backstage.wpesvc.net/docs/default/system/catalyst-software-delivery-platform/guides/tools-and-support/local-dev-tools/#working-with-google-docker-registries)
      \n* [Catalyst: Application Container image](https://backstage.wpesvc.net/docs/default/system/catalyst-software-delivery-platform/#container-image)
      \n
      \nBeyond containers, we also recommend using Google Artifact Registry to publish and consume internal libraries and packages.
      At this time, we recommend and support its use
      [for Python packages](https://backstage.wpesvc.net/docs/default/system/catalyst-software-delivery-platform/guides/tools-and-support/publish-and-consume-libraries/).
      Other languages may be supported as needs arise.
      If you have a need to publish and consume packages internally from other languages supported by Google Artifact Registry, reach out to
      [#team-pe-catalyst](https://wpengine.slack.com/archives/C014MSZQMQC) to get that work prioritized.
      For example, Google Artifact Registry has support for these languages, but work is needed to enable it:
      \n* [Go modules](https://cloud.google.com/artifact-registry/docs/go/store-go)
      \n* [node packages](https://cloud.google.com/artifact-registry/docs/nodejs/store-nodejs) (via npm and yarn)`,
  },
  {
    timeline: [
      {
        moved: MovedState.NoChange,
        ringId: 'Adopt',
        date: new Date('2022-08-05'),
      },
    ],
    url: '#',
    key: 'celery',
    id: 'celery',
    title: 'Framework: Celery',
    quadrant: 'languages-and-frameworks',
    description: `Use this abstraction rather than directly binding to specific underlying message broker to improve portability in Python
      apps that require a job queue. Pubsub / worker pattern is preferred.`,
    // last_updated: '10/23/2019',
    // last_reviewed: '8/5/2022',
  },
  {
    timeline: [
      {
        moved: MovedState.NoChange,
        ringId: 'Hold',
        date: new Date('2022-08-05'),
      },
    ],
    url: '#',
    key: 'code-igniter',
    id: 'code-igniter',
    title: 'Framework: CodeIgniter',
    quadrant: 'languages-and-frameworks',
    description: `New projects should not use PHP except in extending existing PHP code (including WordPress).`,
    // last_updated: '1/16/2017',
    // last_reviewed: '1/14/2022',
  },
  {
    timeline: [
      {
        moved: MovedState.NoChange,
        ringId: 'Adopt',
        date: new Date('2022-08-05'),
      },
    ],
    url: '#',
    key: 'django',
    id: 'django',
    title: 'Framework: Django',
    quadrant: 'languages-and-frameworks',
    description: `Consider Go for new services, but Python remains a team choice. Django is the mainstream framework most Python services
      use for API and database support.`,
    // last_updated: '1/14/2022',
    // last_reviewed: '8/5/2022',
  },
  {
    timeline: [
      {
        moved: MovedState.NoChange,
        ringId: 'Hold',
        date: new Date('2022-08-05'),
      },
    ],
    url: '#',
    key: 'flywheel-design-system',
    id: 'flywheel-design-system',
    title: 'UI: Flywheel Design System',
    quadrant: 'languages-and-frameworks',
    description: `Continue use within Flywheel apps. Hold for new UIs.`,
    // last_updated: '2/1/2022',
    // last_reviewed: '2/1/2022',
  },
  {
    timeline: [
      {
        moved: MovedState.NoChange,
        ringId: 'Adopt',
        date: new Date('2022-08-05'),
      },
    ],
    url: '/docs/default/system/user-portal/How-to-Add-a-React-Component/',
    key: 'react',
    id: 'react',
    title: 'UI: React',
    quadrant: 'languages-and-frameworks',
    description: `The [Unicorn Design System](https://wpengine.atlassian.net/wiki/spaces/PROD/pages/2626683881/Unicorn+Design+System)
      standardized on React for its component library. Use React, Unicorn Design System and its component library for new experiences.
      Convert User Portal pages to React when making any substantive changes to the page. For more, see the
      [User Portal Technology Radar](/portal-tech-radar).`,
    // last_updated: '2/18/2022',
    // last_reviewed: '2/18/2022',
  },
  {
    timeline: [
      {
        moved: MovedState.NoChange,
        ringId: 'Adopt',
        date: new Date('2022-08-05'),
      },
    ],
    url: 'https://wpengine.atlassian.net/wiki/spaces/PROD/pages/2626683881/Unicorn+Design+System',
    key: 'unicorn-design-system-components',
    id: 'unicorn-design-system-components',
    title: 'UI: Unicorn Design System',
    quadrant: 'languages-and-frameworks',
    description: `Required for new UIs in the User Portal. These components are the manifestation of the
      [Unicorn Design System](https://wpengine.atlassian.net/wiki/spaces/PROD/pages/2626683881/Unicorn+Design+System)
      in React code within the User Portal. The use of UDS and its component library within React are required for all new pages, or when
      substantial modifications are made to any existing page. The best way to view the available components is within
      [Storybook](/docs/default/system/user-portal/Storybook-quickstart-guide/). For more on Storybook see the Tools
      quadrant.
      \nTeams are encouraged to extend Unicorn through the
      [Unicorn Design System (UDS) Intake Process](https://wpengine.atlassian.net/wiki/spaces/SSPG/pages/2880045100/Unicorn+Design+System+UDS+Intake+Process)
      should the component library be missing a component required for your design.`,
    // last_updated: '2/1/2022',
    // last_reviewed: '2/1/2022',
  },
  {
    timeline: [
      {
        moved: MovedState.NoChange,
        ringId: 'Hold',
        date: new Date('2022-08-05'),
      },
    ],
    url: '#',
    key: 'vue',
    id: 'vue',
    title: 'UI: Vue',
    quadrant: 'languages-and-frameworks',
    description: `Continue use within Flywheel apps. Hold for new UIs. Longer term, we would prefer for new UIs to use React and the
      Unicorn Design System.`,
    // last_updated: '3/4/2022',
    // last_reviewed: '3/7/2022',
  },
  {
    timeline: [
      {
        moved: MovedState.NoChange,
        ringId: 'Hold',
        date: new Date('2022-08-05'),
      },
    ],
    url: '#',
    key: 'c-language',
    id: 'c-language',
    title: 'Language: C',
    quadrant: 'languages-and-frameworks',
    description: `Customizations to open source C projects (e.g., nginx) are acceptable (though maintaining forks is undesirable).`,
    // last_updated: '10/23/2019',
    // last_reviewed: '2/18/2022',
  },
  {
    timeline: [
      {
        moved: MovedState.NoChange,
        ringId: 'Adopt',
        date: new Date('2022-08-05'),
      },
    ],
    url: '#',
    key: 'golang',
    id: 'golang',
    title: 'Language: Golang',
    quadrant: 'languages-and-frameworks',
    description: `Go is our preferred language for services. Go especially shines for high concurrency, systems programming.
      \nSee the [WP Engine Golang Style Guide](https://wpengine.atlassian.net/wiki/spaces/SE/pages/653819911/WP Engine+Golang+Style+Guide).`,
    // last_updated: '2/18/2022',
    // last_reviewed: '2/18/2022',
  },
  {
    timeline: [
      {
        moved: MovedState.NoChange,
        ringId: 'Prohibited',
        date: new Date('2022-08-05'),
      },
    ],
    url: '#',
    key: 'java',
    id: 'java',
    title: 'Language: Java',
    quadrant: 'languages-and-frameworks',
    description: `Current Oracle licensing makes Java(TM) a non-starter. OpenJDK is possible alternative.`,
  },
  {
    timeline: [
      {
        moved: MovedState.NoChange,
        ringId: 'Hold',
        date: new Date('2022-08-05'),
      },
    ],
    url: '#',
    key: 'java-script',
    id: 'java-script',
    title: 'Language: JavaScript',
    quadrant: 'languages-and-frameworks',
    description: `We prefer the maintainability of TypeScript over JavaScript.`,
    // last_updated: '10/23/2019',
    // last_reviewed: '2/11/2022',
  },
  {
    timeline: [
      {
        moved: MovedState.Up,
        ringId: 'Adopt',
        date: new Date('2022-08-05'),
      },
    ],
    url: '#',
    key: 'node-js',
    id: 'node-js',
    title: 'Language: NodeJS',
    quadrant: 'languages-and-frameworks',
    description: `Use when needed to run best-of-breed frontend dev tools, such as webpack or eslint.
      \nNodeJS is approved for API services developed with TypeScript.
      \nFollow [Node.js Security Guidance](https://wpengine.atlassian.net/wiki/spaces/SECACT/pages/3095724313/Node.js+Security+Guidance)
      to protect against [npm supply chain attacks](https://thehackernews.com/2022/03/a-threat-actor-dubbed-red-lili-has-been.html).`,
    // last_updated: '4/4/2022',
    // last_reviewed: '2/11/2022',
  },
  {
    timeline: [
      {
        moved: MovedState.NoChange,
        ringId: 'Adopt',
        date: new Date('2022-08-05'),
      },
    ],
    url: '#',
    key: 'php',
    id: 'php',
    title: 'Language: PHP',
    quadrant: 'languages-and-frameworks',
    description: `Adopt for WordPress themes/plugins. Hold for other services and tools.
      \nSee [WP Engine PHP Coding Style](https://wpengine.atlassian.net/wiki/spaces/SE/pages/64488262/WP+Engine+PHP+Coding+Style).`,
    // last_updated: '12/5/2016',
    // last_reviewed: '2/18/2022',
  },
  {
    timeline: [
      {
        moved: MovedState.NoChange,
        ringId: 'Adopt',
        date: new Date('2022-08-05'),
      },
    ],
    url: '#',
    key: 'python',
    id: 'python',
    title: 'Language: Python',
    quadrant: 'languages-and-frameworks',
    description: `Python 3 should be used whenever possible. Python 2 is prohibited. Consider Go for new services, but Python remains a
      team choice.
      \nSee [WP Engine Python Coding Style](https://wpengine.atlassian.net/wiki/spaces/SE/pages/51871979/WP+Engine+Python+Coding+Style).`,
    // last_updated: '3/9/2022',
    // last_reviewed: '3/9/2022',
  },
  {
    timeline: [
      {
        moved: MovedState.NoChange,
        ringId: 'Hold',
        date: new Date('2022-08-05'),
      },
    ],
    url: '#',
    key: 'ruby',
    id: 'ruby',
    title: 'Language: Ruby',
    quadrant: 'languages-and-frameworks',
    description: `We prefer Go and Python for services. Generally avoid Ruby for new services. Ruby is allowed for UIs which require a
      back end, though TypeScript UIs / SPAs are encouraged.
      \nNew User Portal UIs are encouraged to use the
      [Pattern: API-Centric UIs calling User-Centric APIs](/docs/default/Component/user-centric-apis/), reducing the Ruby
      footprint of the Portal over time.`,
    // last_updated: '2/18/2022',
    // last_reviewed: '2/18/2022',
  },
  {
    timeline: [
      {
        moved: MovedState.NoChange,
        ringId: 'Required',
        date: new Date('2022-08-05'),
      },
    ],
    url: '#',
    key: 'type-script',
    id: 'type-script',
    title: 'Language: TypeScript',
    quadrant: 'languages-and-frameworks',
    description: `Required for NodeJS/React services and components. Preferred over Javascript.
      \nSee the
      [WP Engine React + Typescript Code Style Guide](https://wpengine.atlassian.net/wiki/spaces/SE/pages/1077149698/WP+Engine+React+Typescript+Code+Style+Guide).`,
    // last_updated: '10/23/2019',
    // last_reviewed: '2/11/2022',
  },
  {
    timeline: [
      {
        moved: MovedState.NoChange,
        ringId: 'Adopt',
        date: new Date('2022-08-05'),
      },
    ],
    url: '#',
    key: 'docker-hub',
    id: 'docker-hub',
    title: 'Distribution: Docker Hub',
    quadrant: 'languages-and-frameworks',
    description: `Use for public images that we need to distribute to customers. Software supply chain concerns exist for the use of
      Dockerhub base images, but status quo includes the use of public base images today.
      \nNot for use for artifact management in continuous deployment for artifacts built by our pipelines and used internally. Not for use for
      distribution directly to farm. Use Google Artifact Registry instead for these use cases. Note that we are shifting from Google Container
      Registry. See their guidance for specifics.
      \nSee:\n
      \n* [Security Standards and Guidelines for Code Repositories and Images](https://wpengine.atlassian.net/wiki/spaces/SECACT/pages/1440088079/Security+Standards+and+Guidelines+for+Code+Repositories+and+Images)
      \n* [WP Engine Containerized Workloads Policy](https://drive.google.com/file/d/1Zvw2K8l_ZM0uRe2iskGa1pBRCoFrq7gG/view)
      \n* [Guidelines for Container Patch Management](https://wpengine.atlassian.net/wiki/spaces/SECACT/pages/2551382037/Guidelines+for+Container+Patch+Management)`,
    // last_updated: '8/24/2022',
    // last_reviewed: '2/18/2022',
  },
  {
    timeline: [
      {
        moved: MovedState.NoChange,
        ringId: 'Adopt',
        date: new Date('2022-08-05'),
      },
    ],
    url: '#',
    key: 'npm',
    id: 'npm',
    title: 'Distribution: npm',
    quadrant: 'languages-and-frameworks',
    description: `OK to use for distributing public node packages.
      \nFollow [Node.js Security Guidance](https://wpengine.atlassian.net/wiki/spaces/SECACT/pages/3095724313/Node.js+Security+Guidance) to
      protect against [npm supply chain attacks](https://thehackernews.com/2022/03/a-threat-actor-dubbed-red-lili-has-been.html)`,
    // last_updated: '4/2/2022',
    // last_reviewed: '2/18/2022',
  },
  {
    timeline: [
      {
        moved: MovedState.NoChange,
        ringId: 'Required',
        date: new Date('2022-08-05'),
      },
    ],
    url: '#',
    key: 'git',
    id: 'git',
    title: 'SCM: Git',
    quadrant: 'languages-and-frameworks',
    description: `We use git as our version control system, on the GitHub hosting service.`,
    // last_updated: '12/5/2016',
    // last_reviewed: '2/18/2022',
  },
  {
    timeline: [
      {
        moved: MovedState.NoChange,
        ringId: 'Required',
        date: new Date('2022-08-05'),
      },
    ],
    url: '#',
    key: 'github',
    id: 'github',
    title: 'SCM: GitHub',
    quadrant: 'languages-and-frameworks',
    description: `Projects must use the wpengine or getflywheel GitHub organizations for source control.
      \nSee [GitHub Access](https://wpengine.atlassian.net/wiki/spaces/PE/pages/2738094113/GitHub+Access) to get access.
      \nFollow the steps in
      [Creating GitHub Repositories](https://wpengine.atlassian.net/wiki/spaces/SE/pages/654082714/Creating+GitHub+Repositories)
      to create new repos which match WP Engine GitHub management and security policies.
      \nSee:\n
      \n* [Change Management Policy](https://drive.google.com/file/d/1uQb5ArmPDVgavTgM8KgoUgY_cN5--wMt/view)
      \n* [Change Management Policy - Implementation Guidelines](https://drive.google.com/file/d/1Rdq2xwlJUacBggHXvMz_gFLlSMqpUsP4/view)
      for requirements such as CODEOWNERS
      \n* [Secure Configuration Baseline - GitHub](https://docs.google.com/document/d/1O6LIf0pRkaAUpSJawmgXZL48Zetaf83uwrney_10qvI/edit)
      \n* [Security Standards and Guidelines for Code Repositories and Images](https://wpengine.atlassian.net/wiki/spaces/SECACT/pages/1440088079/Security+Standards+and+Guidelines+for+Code+Repositories+and+Images).`,
    // last_updated: '2/18/2022',
    // last_reviewed: '2/18/2022',
  },
  {
    timeline: [
      {
        moved: MovedState.NoChange,
        ringId: 'Adopt',
        date: new Date('2022-08-05'),
      },
    ],
    url: '#',
    key: 'amazon-web-services',
    id: 'amazon-web-services',
    title: 'Cloud: Amazon Web Services',
    quadrant: 'Platforms',
    description: `Use for WordPress farm hosting, or farm regional components for AWS hosting, but not for central services.
      \nSee also:\n
      \n* [Secure Configuration Baseline - Amazon Web Services (AWS) Console](https://docs.google.com/document/d/1Ua9OdzJSc-n2NzwZHTR03ItorN9ZAxvvSLLc4_Jtia8/edit)`,
    // last_updated: '1/7/2022',
    // last_reviewed: '1/7/2022',
  },
  {
    timeline: [
      {
        moved: MovedState.NoChange,
        ringId: 'Adopt',
        date: new Date('2022-08-05'),
      },
    ],
    url: '#',
    key: 'azure',
    id: 'azure',
    title: 'Cloud: Azure',
    quadrant: 'Platforms',
    description: `Use for WordPress farm hosting, or farm regional components for Azure hosting, but not for central services.`,
    // last_updated: '1/7/2022',
    // last_reviewed: '1/7/2022',
  },
  {
    timeline: [
      {
        moved: MovedState.NoChange,
        ringId: 'Prohibited',
        date: new Date('2022-08-05'),
      },
    ],
    url: '#',
    key: 'digital-ocean',
    id: 'digital-ocean',
    title: 'Cloud: Digital Ocean',
    quadrant: 'Platforms',
    description: `Migrate all workloads to other cloud providers. It is understood this migration may take a while.`,
    // last_updated: '1/7/2022',
    // last_reviewed: '1/7/2022',
  },
  {
    timeline: [
      {
        moved: MovedState.NoChange,
        ringId: 'Adopt',
        date: new Date('2022-08-05'),
      },
    ],
    url: '#',
    key: 'google-cloud-platform',
    id: 'google-cloud-platform',
    title: 'Cloud: Google Cloud Platform',
    quadrant: 'Platforms',
    description: `Use for WordPress farm hosting, or farm regional components for AWS hosting. Use SDP on GCP for central services.
      \nSee\n
      \n* [Secure Configuration Baseline - Google Cloud Platform (GCP) Console](https://docs.google.com/document/d/1e7v52vtOTU3Kzl23gJHwZlL0LJj1LHr39FPPFKtmac4/edit)`,
    // last_updated: '1/7/2022',
    // last_reviewed: '1/7/2022',
  },
  {
    timeline: [
      {
        moved: MovedState.NoChange,
        ringId: 'Hold',
        date: new Date('2022-08-05'),
      },
    ],
    url: '#',
    key: 'heroku',
    id: 'heroku',
    title: 'Cloud: Heroku',
    quadrant: 'Platforms',
    description: `New Services, including UIs, should be on Catalyst SDP.`,
    // last_updated: '1/6/2022',
    // last_reviewed: '1/6/2022',
  },
  {
    timeline: [
      {
        moved: MovedState.NoChange,
        ringId: 'Prohibited',
        date: new Date('2022-08-05'),
      },
    ],
    url: '#',
    key: 'linode',
    id: 'linode',
    title: 'Cloud: Linode',
    quadrant: 'Platforms',
    description: `Migrate all workloads to other cloud providers. It is understood this migration may take a while.`,
    // last_updated: '1/7/2022',
    // last_reviewed: '1/7/2022',
  },
  {
    timeline: [
      {
        moved: MovedState.NoChange,
        ringId: 'Prohibited',
        date: new Date('2022-08-05'),
      },
    ],
    url: '#',
    key: 'rackspace',
    id: 'rackspace',
    title: 'Cloud: Rackspace',
    quadrant: 'Platforms',
    description: `Rackspace should be used to meet geographical requirements that cannot be met with other infrastructure providers.
      \nSee [Secure Configuration Baseline - Rackspace (RAX) Console](https://docs.google.com/document/d/1sUdscoSgI0xdOc6eMgKMNfda_xpqptoMOYKb5t5YsQ8/edit).`,
    // last_updated: '6/5/2019',
    // last_reviewed: '1/7/2022',
  },
  {
    timeline: [
      {
        moved: MovedState.Down,
        ringId: 'Hold',
        date: new Date('2022-08-05'),
      },
    ],
    url: '#',
    key: 'aws-aurora',
    id: 'aws-aurora',
    title: 'DB: AWS Aurora',
    quadrant: 'Platforms',
    description: `Used for CMDB. Use for farm central databases and as database for farm elements running on AWS only.`,
    // last_updated: '8/24/2022',
    // last_reviewed: '8/24/2022',
  },
  {
    timeline: [
      {
        moved: MovedState.Down,
        ringId: 'Hold',
        date: new Date('2022-08-05'),
      },
    ],
    url: '#',
    key: 'aws-rds',
    id: 'aws-rds',
    title: 'DB: AWS RDS',
    quadrant: 'Platforms',
    description: `MySQL only. Use for database for farm elements running on AWS only.`,
    // last_updated: '8/24/2022',
    // last_reviewed: '8/24/2022',
  },
  {
    timeline: [
      {
        moved: MovedState.NoChange,
        ringId: 'Hold',
        date: new Date('2022-08-05'),
      },
    ],
    url: '#',
    key: 'dynamo-db',
    id: 'dynamo-db',
    title: 'DB: DynamoDB',
    quadrant: 'Platforms',
    description: `Dynamo DB in practice has proven difficult for developers to adapt to changing requirements.`,
    // last_updated: '12/5/2016',
    // last_reviewed: '1/7/2022',
  },
  {
    timeline: [
      {
        moved: MovedState.NoChange,
        ringId: 'Hold',
        date: new Date('2022-08-05'),
      },
    ],
    url: '#',
    key: 'firestore',
    id: 'codereviews',
    title: 'DB: Firestore',
    quadrant: 'Platforms',
    description: `We should not use this until we've established best-practices or standard patterns for NoSQL databases and how we do
      things like backups & data migration for Firestore.
      \nSome of the rationale for shifting Firestore to Hold are captured in
      [this document](https://docs.google.com/document/d/1EWfgh1fhZA5emwdmu27AhFF-XTU-lLBWBVnzXXM0RDc/edit).`,
    // last_updated: '1/7/2022',
    // last_reviewed: '1/24/2022',
  },
  {
    timeline: [
      {
        moved: MovedState.NoChange,
        ringId: 'Adopt',
        date: new Date('2022-08-05'),
      },
    ],
    url: '#',
    key: 'google-cloudSQL',
    id: 'google-cloudSQL',
    title: 'DB: Google CloudSQL',
    quadrant: 'Platforms',
    description: `Postgres or MySQL. `,
    // last_updated: '2/21/2018',
    // last_reviewed: '1/7/2022',
  },
  {
    timeline: [
      {
        moved: MovedState.NoChange,
        ringId: 'Adopt',
        date: new Date('2022-08-05'),
      },
    ],
    url: '#',
    key: 'google-memorystore',
    id: 'google-memorystore',
    title: 'DB: Google Memorystore',
    quadrant: 'Platforms',
    description: `Memorystore is the preferred method for supporting Redis or Memcached, favored over our own deployments. Consider our own
      deployments of Redis or Memcached only when there is substantial cost advantage, like in the farm.`,
    // last_updated: '2/1/2022',
    // last_reviewed: '2/1/2022',
  },
  {
    timeline: [
      {
        moved: MovedState.NoChange,
        ringId: 'Adopt',
        date: new Date('2022-08-05'),
      },
    ],
    url: '#',
    key: 'influx',
    id: 'influx',
    title: 'DB: Influx',
    quadrant: 'Platforms',
    description: `Using both Open Source and Enterprise versions. Consider cost when using Enterprise.`,
    // last_updated: '1/7/2022',
    // last_reviewed: '1/7/2022',
  },
  {
    timeline: [
      {
        moved: MovedState.NoChange,
        ringId: 'Adopt',
        date: new Date('2022-08-05'),
      },
    ],
    url: '#',
    key: 'memcached',
    id: 'memcached',
    title: 'DB: Memcached',
    quadrant: 'Platforms',
    description: `Memcached is usable where necessary, but Redis is preferred. Where possible, use Cloud-Managed services like
    [Memorystore](https://cloud.google.com/memorystore)
    \nSee [Secure Configuration Baseline - memcached - Platform](https://docs.google.com/document/d/19jrEEHp_r2v2d7Q_qOs7RAwLQfJJLEALMK3hJboEdyE/edit).`,
    // last_updated: '1/7/2022',
    // last_reviewed: '1/7/2022',
  },
  {
    timeline: [
      {
        moved: MovedState.NoChange,
        ringId: 'Hold',
        date: new Date('2022-08-05'),
      },
    ],
    url: '#',
    key: 'mongo-db',
    id: 'mongo-db',
    title: 'DB: MongoDB',
    quadrant: 'Platforms',
    description: `Significant challenges exist to operating MongoDB at scale. Used by Git Push.`,
    // last_updated: '12/5/2016',
    // last_reviewed: '1/7/2022',
  },
  {
    timeline: [
      {
        moved: MovedState.NoChange,
        ringId: 'Adopt',
        date: new Date('2022-08-05'),
      },
    ],
    url: '#',
    key: 'mysql',
    id: 'mysql',
    title: 'DB: MySQL',
    quadrant: 'Platforms',
    description: `(Percona)
      \nAdopted for use on the farm.
      \nSee [Secure Configuration Baseline - MySQL - Platform](https://docs.google.com/document/d/15VrWkj3Y8HZjLldkJ_Gsvcw8gUgS9koruuqrBcNUAaI/edit).`,
    // last_updated: '12/5/2016',
    // last_reviewed: '1/7/2022',
  },
  {
    timeline: [
      {
        moved: MovedState.NoChange,
        ringId: 'Hold',
        date: new Date('2022-08-05'),
      },
    ],
    url: '#',
    key: 'neptune',
    id: 'neptune',
    title: 'DB: Neptune',
    quadrant: 'Platforms',
    description: `Graph database used by Snappyshot cleanup, but for a very specific use case. We would prefer not to have more instances.`,
    // last_updated: '10/16/2019',
    // last_reviewed: '1/7/2022',
  },
  {
    timeline: [
      {
        moved: MovedState.NoChange,
        ringId: 'Adopt',
        date: new Date('2022-08-05'),
      },
    ],
    url: '#',
    key: 'postgresql',
    id: 'postgresql',
    title: 'DB: PostgreSQL',
    quadrant: 'Platforms',
    description: `PostgreSQL is preferred for Heroku applications and CloudSQL stores for generated services.
      \nHeroku's PostgreSQL implementation on base plans does not offer security protection. Since the database is exposed to the internet
      without network protections, it is impossible to protect against brute force attacks that attempt to discover database credentials.
      Additionally, logging is insufficient to detect and investigate incidents. Business critical databases should NOT be hosted in Heroku
      unless part of Private Spaces.`,
    // last_updated: '8/24/2022',
    // last_reviewed: '8/24/2022',
  },
  {
    timeline: [
      {
        moved: MovedState.NoChange,
        ringId: 'Adopt',
        date: new Date('2022-08-05'),
      },
    ],
    url: '#',
    key: 'redis',
    id: 'redis',
    title: 'DB: Redis',
    quadrant: 'Platforms',
    description: `OK to use for caching. Consider other options for database or message queue. Where possible, use Cloud-Managed services
      like [Memorystore](https://cloud.google.com/memorystore).`,
    // last_updated: '6/30/2017',
    // last_reviewed: '1/7/2022',
  },
  {
    timeline: [
      {
        moved: MovedState.NoChange,
        ringId: 'Hold',
        date: new Date('2022-08-05'),
      },
    ],
    url: '#',
    key: 'simple-db',
    id: 'simple-db',
    title: 'DB: SimpleDB',
    quadrant: 'Platforms',
    description: `Used by Snappyshot. On hold since this seems to lacking investment from AWS.`,
    // last_updated: '10/16/2019',
    // last_reviewed: '1/7/2022',
  },
  {
    timeline: [
      {
        moved: MovedState.Up,
        ringId: 'Adopt',
        date: new Date('2023-01-18'),
      },
      {
        moved: MovedState.Up,
        ringId: 'Evaluate',
        date: new Date('2022-08-05'),
      },
    ],
    url: '/docs/default/resource/backstage-catalog/',
    key: 'backstage',
    id: 'backstage',
    title: 'APIs: Backstage',
    quadrant: 'Platforms',
    description: `We have implemented Backstage
      [as our internal developer portal](https://backstage.wpesvc.net/catalog/default/system/backstage/decisions?record=0001-use-backstage-as-the-developer-portal.md). We
      [have determined](https://backstage.wpesvc.net/catalog/default/domain/apis/decisions?record=0008-api-documentation.md)
      to consolidate all API documentation on Backstage. All teams should work to onboard their APIs,
      services, and systems.`,
    // last_updated: '1/18/2023',
    // last_reviewed: '1/18/2023',
  },
  {
    timeline: [
      {
        moved: MovedState.Down,
        ringId: 'Hold',
        date: new Date('2022-08-05'),
      },
    ],
    url: 'https://backstage.wpesvc.net/docs/default/resource/backstage-catalog/documenting-apis/',
    key: 'google-cloud-endpoints-portal',
    id: 'google-cloud-endpoints-portal',
    title: 'APIs: GCP Endpoints Portal',
    quadrant: 'Platforms',
    description: `In March 2022, Google
      [announced that they're deprecating the Endpoints Portal](https://cloud.google.com/endpoints/docs/deprecations). As a result, we no
      longer recommend that teams leverage it.
      \nSome teams are using the Developer Portal to publish API documentation to a common location, who were using it prior to deprecation.
      This hosts docs for some older apis we created. It is available [here](https://endpointsportal.wp-engine-corporate.cloud.goog/).
      \nIt connects to these services and supports OpenAPI & gRPC.
      \nWe
      [have determined](https://backstage.wpesvc.net/catalog/default/domain/apis/decisions?record=0008-api-documentation.md)
      to consolidate all API documentation on Backstage.`,
    // last_updated: '7/27/2022',
    // last_reviewed: '8/5/2022',
  },
  {
    timeline: [
      {
        moved: MovedState.NoChange,
        ringId: 'Adopt',
        date: new Date('2022-08-05'),
      },
    ],
    url: '#',
    key: 'aws-s3',
    id: 'aws-s3',
    title: 'Storage: AWS S3',
    quadrant: 'Platforms',
    description: `Use within AWS farm elements. We prefer that central services use GCP elements such as Google Cloud Storage.`,
    // last_updated: '12/5/2016',
    // last_reviewed: '2/18/2022',
  },
  {
    timeline: [
      {
        moved: MovedState.NoChange,
        ringId: 'Adopt',
        date: new Date('2022-08-05'),
      },
    ],
    url: '#',
    key: 'google-cloud-storage',
    id: 'google-cloud-storage',
    title: 'Storage: GCP Storage',
    quadrant: 'Platforms',
    description: `Google Cloud Storage is the preferred storage solution and general use cases.`,
    // last_updated: '6/30/2017',
    // last_reviewed: '2/18/2022',
  },
  {
    timeline: [
      {
        moved: MovedState.NoChange,
        ringId: 'Hold',
        date: new Date('2022-08-05'),
      },
    ],
    url: '#',
    key: 'centos',
    id: 'centos',
    title: 'OS: CentOS',
    quadrant: 'Platforms',
    description: `Security's
      [Containerized Workload Policy Guidance](https://docs.google.com/document/d/1A_7EWYY4lzrNx2UDXLxY4PbK-oEDoFglnBtU64mHiuA/edit)
      provides pending updates to the currently published
      [Containerized Workloads Policy](https://drive.google.com/file/d/1Zvw2K8l_ZM0uRe2iskGa1pBRCoFrq7gG/view)
      and
      [Containerized Workloads Policy - Implementation Guidelines](https://drive.google.com/file/d/1Xw2fk7fwFLOrQElyjZ3FzGdMnx0zttqs/view).
      Follow security guidance for acceptable base images.
      \nWe have standardized on Ubuntu over CentOS.`,
    // last_updated: '1/28/2022',
    // last_reviewed: '1/28/2022',
  },
  {
    timeline: [
      {
        moved: MovedState.NoChange,
        ringId: 'Hold',
        date: new Date('2022-08-05'),
      },
    ],
    url: '#',
    key: 'alpine-base-image',
    id: 'alpine-base-image',
    title: 'OS: Base Images: Alpine',
    quadrant: 'Platforms',
    description: `Security's
      [Containerized Workload Policy Guidance](https://docs.google.com/document/d/1A_7EWYY4lzrNx2UDXLxY4PbK-oEDoFglnBtU64mHiuA/edit)
      provides pending updates to the currently published
      [Containerized Workloads Policy](https://drive.google.com/file/d/1Zvw2K8l_ZM0uRe2iskGa1pBRCoFrq7gG/view)
      and
      [Containerized Workloads Policy - Implementation Guidelines](https://drive.google.com/file/d/1Xw2fk7fwFLOrQElyjZ3FzGdMnx0zttqs/view).
      Follow security guidance for acceptable base images.
      \nThese state that "Alpine images are not approved for use as final base images for WP Engine images." Refer to the guidance for
      recommended / required alternatives.
      \nSee [Guidelines for Container Patch Management](https://wpengine.atlassian.net/wiki/spaces/SECACT/pages/2551382037/Guidelines+for+Container+Patch+Management).`,
    // last_updated: '1/28/2022',
    // last_reviewed: '1/28/2022',
  },
  {
    timeline: [
      {
        moved: MovedState.NoChange,
        ringId: 'Adopt',
        date: new Date('2022-08-05'),
      },
    ],
    url: '#',
    key: 'base-images',
    id: 'base-images',
    title: 'OS: Container Base Images',
    quadrant: 'Platforms',
    description: `Security's
      [Containerized Workload Policy Guidance](https://docs.google.com/document/d/1A_7EWYY4lzrNx2UDXLxY4PbK-oEDoFglnBtU64mHiuA/edit)
      provides pending updates to the currently published
      [Containerized Workloads Policy](https://drive.google.com/file/d/1Zvw2K8l_ZM0uRe2iskGa1pBRCoFrq7gG/view)
      and
      [Containerized Workloads Policy - Implementation Guidelines](https://drive.google.com/file/d/1Xw2fk7fwFLOrQElyjZ3FzGdMnx0zttqs/view).
      Follow security guidance for acceptable base images.
      \nSee [System Security Requirements](https://wpengine.atlassian.net/wiki/spaces/SECACT/pages/2551185432/System+Security+Requirements)
      including Secure Configuration Baselines
      and
      [Guidelines for Container Patch Management](https://wpengine.atlassian.net/wiki/spaces/SECACT/pages/2551382037/Guidelines+for+Container+Patch+Management).`,
    // last_updated: '1/25/2022',
    // last_reviewed: '1/28/2022',
  },
  {
    timeline: [
      {
        moved: MovedState.NoChange,
        ringId: 'Adopt',
        date: new Date('2022-08-05'),
      },
    ],
    url: '#',
    key: 'debian',
    id: 'debian',
    title: 'OS: Debian',
    quadrant: 'Platforms',
    description: `Security's
      [Containerized Workload Policy Guidance](https://docs.google.com/document/d/1A_7EWYY4lzrNx2UDXLxY4PbK-oEDoFglnBtU64mHiuA/edit)
      provides pending updates to the currently published
      [Containerized Workloads Policy](https://drive.google.com/file/d/1Zvw2K8l_ZM0uRe2iskGa1pBRCoFrq7gG/view)
      and
      [Containerized Workloads Policy - Implementation Guidelines](https://drive.google.com/file/d/1Xw2fk7fwFLOrQElyjZ3FzGdMnx0zttqs/view).
      Follow security guidance for acceptable base images.
      \nOutside containerized workloads, the use of Debian is allowed, where a full linux system is necessary. The preference Ubuntu where
      possible.`,
    // last_updated: '1/28/2022',
    // last_reviewed: '1/28/2022',
  },
  {
    timeline: [
      {
        moved: MovedState.NoChange,
        ringId: 'Adopt',
        date: new Date('2022-08-05'),
      },
    ],
    url: '#',
    key: 'google-container-optimized-os',
    id: 'google-container-optimized-os',
    title: 'OS: Container-Optimized OS',
    quadrant: 'Platforms',
    description: `Within Container Engine, hosts should run Google Container-Optimized OS as the secure base platform.`,
    // last_updated: '2/28/2018',
    // last_reviewed: '1/28/2022',
  },
  {
    timeline: [
      {
        moved: MovedState.NoChange,
        ringId: 'Adopt',
        date: new Date('2022-08-05'),
      },
    ],
    url: '#',
    key: 'ubuntu',
    id: 'ubuntu',
    title: 'OS: Ubuntu',
    quadrant: 'Platforms',
    description: `Security's
      [Containerized Workload Policy Guidance](https://docs.google.com/document/d/1A_7EWYY4lzrNx2UDXLxY4PbK-oEDoFglnBtU64mHiuA/edit)
      provides pending updates to the currently published
      [Containerized Workloads Policy](https://drive.google.com/file/d/1Zvw2K8l_ZM0uRe2iskGa1pBRCoFrq7gG/view)
      and
      [Containerized Workloads Policy - Implementation Guidelines](https://drive.google.com/file/d/1Xw2fk7fwFLOrQElyjZ3FzGdMnx0zttqs/view).
      Follow security guidance for acceptable base images.
      \nOutside containerized workloads, the use of Ubuntu is allowed, but the where a full linux system is necessary. The preference Ubuntu
      over Debian where possible.
      \nUbuntu 16.04 is EOLing soon. It is Prohibited, and teams should actively move away from it.`,
    // last_updated: '1/28/2022',
    // last_reviewed: '1/28/2022',
  },
  {
    timeline: [
      {
        moved: MovedState.NoChange,
        ringId: 'Adopt',
        date: new Date('2022-08-05'),
      },
    ],
    url: '#',
    key: 'aws-lambda',
    id: 'aws-lambda',
    title: 'Environment: AWS Lambda',
    quadrant: 'Platforms',
    description: `Adopted for orchestration at AWS (e.g., autoscale watcher jobs). Use for farm elements running on AWS only.`,
    // last_updated: '1/25/2021',
    // last_reviewed: '2/18/2022',
  },
  {
    timeline: [
      {
        moved: MovedState.NoChange,
        ringId: 'Adopt',
        date: new Date('2022-08-05'),
      },
    ],
    url: '#',
    key: 'catalyst-sdp',
    id: 'catalyst-sdp',
    title: 'Environment: Catalyst SDP',
    quadrant: 'Platforms',
    description: `The Catalyst Software Delivery Platform (SDP) is recommended for new services. See documentation at
      [Catalyst SDP Overview](/docs/default/System/catalyst-software-delivery-platform).
      \nSee the [WP Engine Containerized Workloads Policy](https://drive.google.com/file/d/1Zvw2K8l_ZM0uRe2iskGa1pBRCoFrq7gG/view) and
      [Containerized Workloads Policy - Implementation Guidelines](https://drive.google.com/file/d/1Xw2fk7fwFLOrQElyjZ3FzGdMnx0zttqs/view).`,
    // last_updated: '1/7/2022',
    // last_reviewed: '1/7/2022',
  },
  {
    timeline: [
      {
        moved: MovedState.NoChange,
        ringId: 'Adopt',
        date: new Date('2022-08-05'),
      },
    ],
    url: '#',
    key: 'google-cloud-functions',
    id: 'google-cloud-functions',
    title: 'Environment: Cloud Functions',
    quadrant: 'Platforms',
    description: `[Google Cloud Functions](https://wpengine.atlassian.net/wiki/spaces/OS/pages/795837538/Google+CloudFunctions+Architectural+Evaluation+Report)
      appropriate use cases: stateless integration/orchestration where authentication constraints are acceptable.
      \nTeams should continue to invest in the pattern/sample application to make development easier for future teams; and expand the use
      cases to additional applications.`,
    // last_updated: '2/18/2022',
    // last_reviewed: '2/18/2022',
  },
  {
    timeline: [
      {
        moved: MovedState.NoChange,
        ringId: 'Adopt',
        date: new Date('2022-08-05'),
      },
    ],
    url: '#',
    key: 'google-cloud-run',
    id: 'google-cloud-run',
    title: 'Environment: Cloud Run',
    quadrant: 'Platforms',
    description: `We prefer using Catalyst SDP. SDP provides much of the same value, with more standardization, common CI/CD, etc.
      Moreover, Cloud Run cannot run some of our security tooling.
      \nThis includes managed and Anthos variants.`,
    // last_updated: '2/1/2022',
    // last_reviewed: '2/1/2022',
  },
  {
    timeline: [
      {
        moved: MovedState.NoChange,
        ringId: 'Adopt',
        date: new Date('2022-08-05'),
      },
    ],
    url: '#',
    key: 'kubernetes',
    id: 'kubernetes',
    title: 'Environment: Kubernetes',
    quadrant: 'Platforms',
    description: `We recommend the use of Kubernetes through Catalyst SDP. Hold on deploying your own clusters.
      \nSee:\n
      \n* [WP Engine Containerized Workloads Policy](https://drive.google.com/file/d/1Zvw2K8l_ZM0uRe2iskGa1pBRCoFrq7gG/view)
      \n* [Containerized Workloads Policy - Implementation Guidelines](https://drive.google.com/file/d/1Xw2fk7fwFLOrQElyjZ3FzGdMnx0zttqs/view)
      \n* [Secure Configuration Baseline - Google Kubernetes Engine (GKE) Environment](https://docs.google.com/document/d/1QDAjgTSxNLrCaJFi4HPpvGquXfcQVm5Cw5ZROp-_MS0/edit)`,
    // last_updated: '1/7/2022',
    // last_reviewed: '1/7/2022',
  },
  {
    timeline: [
      {
        moved: MovedState.NoChange,
        ringId: 'Hold',
        date: new Date('2022-08-05'),
      },
    ],
    url: '#',
    key: 'apache',
    id: 'apache',
    title: 'Web: Apache',
    quadrant: 'Platforms',
    description: `Apache's memory and performance profile is not great. Where possible we should run lighter weight FastCGI systems.
      \nSee [Secure Configuration Baseline  - Apache](https://docs.google.com/document/d/1CTIgvAJEKZdmHfp1hYAXjRIYZFTS0fRihjxhQDV2hok/edit).`,
    // last_updated: '12/5/2016',
    // last_reviewed: '2/18/2022',
  },
  {
    timeline: [
      {
        moved: MovedState.NoChange,
        ringId: 'Adopt',
        date: new Date('2022-08-05'),
      },
    ],
    url: '#',
    key: 'nginx',
    id: 'nginx',
    title: 'Web: Nginx',
    quadrant: 'Platforms',
    description: `See [Secure Configuration Baseline - Nginx - Platform](https://docs.google.com/document/d/1PqcZ1BB59hY_3_Ed4GuMzDKJ0lIg8C8qpl--6aWpQZo/edit).`,
    // last_updated: '12/5/2016',
    // last_reviewed: '2/18/2022',
  },
  {
    timeline: [
      {
        moved: MovedState.NoChange,
        ringId: 'Adopt',
        date: new Date('2022-08-05'),
      },
    ],
    url: '#',
    key: 'varnish',
    id: 'varnish',
    title: 'Web: Varnish',
    quadrant: 'Platforms',
    description: `See [Secure Configuration Baseline - Varnish - Platform](https://docs.google.com/document/d/1DBxx-2so6VC36gla4bN6g_SbzhZfwpkK-Td0niO3BIg/edit).`,
    // last_updated: '12/5/2016',
    // last_reviewed: '2/18/2022',
  },
  {
    timeline: [
      {
        moved: MovedState.NoChange,
        ringId: 'Adopt',
        date: new Date('2022-08-05'),
      },
    ],
    url: '/catalog/default/domain/apis/decisions?record=0002-align-to-Google-AIP.md',
    key: 'google-aip-api-standards',
    id: 'google-aip-api-standards',
    title: 'APIs: Google AIP-based Standards',
    quadrant: 'Techniques',
    description: `We are beginning a new API standard based on Google AIP. We recommend the majority of new services and their APIs align to
      Google AIP. A future “v2” of the customer API is expected to align to this emerging standard. The new standard is being affected by
      decisions collected in [this folder on our P&E team drive](https://drive.google.com/drive/u/0/folders/1mX4KM_OfcF2Y-pmdTrAMGD54_k0F5Mti),
      but of special importance is the decision
      [002: Align to Google AIP](/catalog/default/domain/apis/decisions?record=0002-align-to-Google-AIP.md). We have created an API
      guild to shape this new standard, and we will support teams using the new standard through the
      [#api-guild](https://wpengine.slack.com/archives/C02BCCATPPV) Slack channel.`,
    // last_updated: '1/14/2022',
    // last_reviewed: '2/8/2022',
  },
  {
    timeline: [
      {
        moved: MovedState.Up,
        ringId: 'Adopt',
        date: new Date('2022-08-05'),
      },
    ],
    url: '/docs/default/Domain/apis/API_Standards/',
    key: 'grpc',
    id: 'grpc',
    title: 'APIs: gRPC',
    quadrant: 'Techniques',
    description: `See the Google AIP-based API Standards item. Consider your client use cases. gRPC as a wire protocol is appropriate for
      some internal APIs/RPC. REST is recommended for external calls exposed to UIs and customers.
      \nCatalyst SDP now supports gRPC ingress. See
      [How-To: Expose application to public internet](https://backstage.wpesvc.net/docs/default/system/catalyst-software-delivery-platform/guides/app-security/expose-application-to-public-internet/#configuration-for-grpc)
      and [CTLST-1657](https://wpengine.atlassian.net/browse/CTLST-1657).`,
    // last_updated: '3/14/2022',
    // last_reviewed: '2/8/2022',
  },
  {
    timeline: [
      {
        moved: MovedState.NoChange,
        ringId: 'Adopt',
        date: new Date('2022-08-05'),
      },
    ],
    url: 'https://backstage.wpesvc.net/docs/default/Domain/apis/API_Standards/',
    key: 'rest',
    id: 'rest',
    title: 'APIs: REST',
    quadrant: 'Techniques',
    description: `REST APIs are our standard for public facing APIs and is the best pattern for supporting UIs running in a web browser.
      \nWhen using gRPC, we recommend using [gRPC Gateway](https://grpc-ecosystem.github.io/grpc-gateway/) to provide a REST API and
      OpenAPI 2 spec for your gRPC service.`,
    // last_updated: '8/4/2022',
    // last_reviewed: '8/5/2022',
  },
  {
    timeline: [
      {
        moved: MovedState.NoChange,
        ringId: 'Hold',
        date: new Date('2022-08-05'),
      },
    ],
    url: '#',
    key: 'legacy-api-standard',
    id: 'legacy-api-standard',
    title: 'APIs: HTTP API Standard',
    quadrant: 'Techniques',
    description: `We recommend the majority of new services and their APIs align to
      [Google AIP](https://drive.google.com/drive/u/0/folders/1mX4KM_OfcF2Y-pmdTrAMGD54_k0F5Mti) rather than the
      [Deprecated WP Engine HTTP API Standard](/docs/default/Domain/apis/legacy_api_standard/).
      You may wish to choose this legacy path if you are extending an existing service with APIs already written to this prior standard,
      or if you have a need to extend the customer API in the v1 timeframe.`,
    // last_updated: '1/14/2022',
    // last_reviewed: '2/8/2022',
  },
  {
    timeline: [
      {
        moved: MovedState.NoChange,
        ringId: 'Adopt',
        date: new Date('2022-08-05'),
      },
    ],
    url: '/docs/default/domain/architecture/Environment_Standard/',
    key: 'standard-environments',
    id: 'standard-environments',
    title: 'CI/CD: Standard Environments',
    quadrant: 'Techniques',
    description: `Our [WP Engine Environment Standard](/docs/default/domain/architecture/Environment_Standard/)
      and [WP Engine CI/CD North Star Vision](/docs/default/domain/architecture/CI_CD_North_Star/)
      call for services to deploy to development, staging, and production environments. Services should deploy to and provide services in
      all of these environments.
      \nWhile the hosting platform is missing today in staging, we are building towards the capability. We would encourage teams to build
      towards this north star vision so they are ready when the general platform environment is ready.
      \nAs our integration dependencies grow, it becomes increasingly important to align our environments. Primarily, development environment
      things should talk to other development things, staging to staging, and production to production. Patterns such as the API-centric
      UI / user-centric API rely on clean environment separation for our growing number of integrations.`,
    // last_updated: '1/14/2022',
    // last_reviewed: '2/8/2022',
  },
  {
    timeline: [
      {
        moved: MovedState.NoChange,
        ringId: 'Adopt',
        date: new Date('2022-08-05'),
      },
    ],
    url: '/docs/default/domain/architecture/Documenting_Architectural_Decisions/',
    key: 'adrs',
    id: 'adrs',
    title: 'Process: Documenting Decisions',
    quadrant: 'Techniques',
    description: `We record architectural decisions. While specifics are a team choice, the most common approaches are Architectural
      Decision Records (ADRs) and RFCs. These describe not only the outcome, but also the options considered, and why we went the way we did.
      \nWhere possible, align your decision process with
      [DACI](https://wpengine.atlassian.net/wiki/spaces/~735669311/pages/2962653221/DACI+-+Drive+clarity+alignment+and+transparency+in+decision+making).
      DACI makes responsibilities clearer by identifying the Driver (will drive the decision making process), Approver (has the final say),
      Contributors (they participate in decision making), and Informed (are notified of decisions)
      \nA lightweight template helps teams learn to make architectural decisions. Key elements prompt the team regarding what to think and
      have conversations about. [MADR](https://adr.github.io/madr/) is encouraged.
      \nWhen decisions are component-specific, they are typically kept in the repo. When decisions span components, teams use a Google Drive
      folder, Confluence, or a top-level repo. One document per decision helps.
      \nWe encourage decisions to link to this Technology Radar, calling out where decisions are aligned with the radar, where the team is
      choosing to break ground on a new technology, or any exceptions. Bring architects into the review as needed.
      \nWhen documenting architecture decisions, refer to
      [WP Engine's Architecture Principles](/docs/default/domain/architecture/Architecture_Principles/)
      to accelerate sound decision making. Teams may wish to document the Architecture Principles which apply as well as when their decision
      conflicts with one or more principles.`,
    // last_updated: '1/14/2022',
    // last_reviewed: '2/8/2022',
  },
  {
    timeline: [
      {
        moved: MovedState.Up,
        ringId: 'Adopt',
        date: new Date('2022-08-05'),
      },
    ],
    url: '/docs/default/domain/architecture/Architecture_Principles/',
    key: 'architecture-principles',
    id: 'architecture-principles',
    title: 'Process: Architecture Principles',
    quadrant: 'Techniques',
    description: `[WP Engine's Architecture Principles](/docs/default/domain/architecture/Architecture_Principles/)
      are an important addition to the
      [Architecture Advice Process](/docs/default/domain/architecture/Architecture_Advice_Process/).
      The principles are brief statements that identify WP Engine's unique, enduring priorities, their rationale, and their implications.
      They help scale decision making by guiding common approaches to problems and decisions. Team members use architectural principles to
      test potential choices and speed up good decision making.
      \nWe encourage you to familiarize yourself with the Architecture Principles, and use them to accelerate sound decision making.
      \nShould you have questions about the principles, or anything regarding the Architecture Advice Process, reach out to us in
      [#architecture](https://wpengine.slack.com/archives/CFGADDBT5).`,
    // last_updated: '5/16/2022',
    // last_reviewed: '8/5/2022',
  },
  {
    timeline: [
      {
        moved: MovedState.NoChange,
        ringId: 'Adopt',
        date: new Date('2022-08-05'),
      },
    ],
    url: '/docs/default/domain/architecture/CI_CD_North_Star/#scorecard',
    key: 'ci-cd-scorecard',
    id: 'ci-cd-scorecard',
    title: 'CI/CD: Scorecard, Prod Checklist',
    quadrant: 'Techniques',
    description: `Teams deploying new services are encouraged to self-evaluate to evaluate readiness, and help form their incremental plans
      to improve their service:\n
      \n* The [Production Checklist](/docs/default/system/catalyst-software-delivery-platform/reference/production-checklist/) for services on
      Catalyst SDP to self-evaluate as they prepare to go live with their application.  For each item in the checklist, links to "how to"
      documentation are included.
      \n* The
      [Scorecard within the CI/CD North Star](/docs/default/domain/architecture/CI_CD_North_Star/)
      helps identify gaps in services' CI/CD processes. This scorecard hints at a possible phasing within second and third level items.
      \nNote that both lists are not must haves, but include recommended elements that should be strongly considered. Each item may not apply
      to every situation, but teams should make a conscious decision about what they choose to implement.`,
    // last_updated: '3/3/2022',
    // last_reviewed: '3/3/2022',
  },
  {
    timeline: [
      {
        moved: MovedState.NoChange,
        ringId: 'Required',
        date: new Date('2022-08-05'),
      },
    ],
    url: '/docs/default/domain/apis/API_Standards/',
    key: 'open-api',
    id: 'open-api',
    title: 'APIs: OpenAPI / Swagger',
    quadrant: 'Techniques',
    description: `Required when your API supports HTTP/REST. Our new API standard based on
      [Google AIP](/docs/default/domain/apis/API_Standards/) requires OpenAPI. Our prior
      [Deprecated WP Engine HTTP API Standard](/docs/default/domain/apis/legacy_api_standard/),
      applicable for some legacy workloads, required specification in Swagger/OpenAPI.`,
    // last_updated: '2/18/2022',
    // last_reviewed: '2/18/2022',
  },
  {
    timeline: [
      {
        moved: MovedState.NoChange,
        ringId: 'Prohibited',
        date: new Date('2022-08-05'),
      },
    ],
    url: '#',
    key: 'customer-data-test',
    id: 'customer-data-test',
    title: 'Testing: Customer content and data',
    quadrant: 'Techniques',
    description: `See the [Secure Software Development Policy](https://drive.google.com/file/d/10OAE_KV4gSOFNHyfirFkQ7ae1pmcU4fz/view\).
      Customer Content, Customer Data, or other sensitive data, as defined by the
      [Data Classification and Retention policy](https://drive.google.com/file/d/1RKVnjiKhGnlOdDaqDDB71VKLzV-9Ta3z/view\),
      must not be used for testing unless all identifying data elements are anonymized, encrypted, or otherwise effectively obscured in the
      event of data compromise. When we talk about Customer Content and Customer Data here, we are referring to our legal definitions in
      our [Terms of service](https://wpengine.com/legal/terms-of-service/\).`,
    // last_updated: '3/9/2022',
    // last_reviewed: '3/9/2022',
  },
  {
    timeline: [
      {
        moved: MovedState.NoChange,
        ringId: 'Prohibited',
        date: new Date('2022-08-05'),
      },
    ],
    url: '#',
    key: 'plain-text',
    id: 'plain-text',
    title: 'Transport: Plaintext',
    quadrant: 'Techniques',
    description: `Even for the publication use case, if its published by WP Engine we don't want to allow our messages to be modified. See
      the [Data Encryption Policy](https://enginet.wpengine.com/resources/security-policies/).\n
      \n* connections over the public Internet must be encrypted
      \n* connections within a trusted network context may be plaintext
      \n* we can provide the ability for our customers to decide plaintext is appropriate for their usage when not interacting with WP Engine`,
    // last_updated: '10/23/2019',
    // last_reviewed: '2/25/2022',
  },
  {
    timeline: [
      {
        moved: MovedState.NoChange,
        ringId: 'Required',
        date: new Date('2022-08-05'),
      },
    ],
    url: '#',
    key: 'tls',
    id: 'tls',
    title: 'Transport: TLS',
    quadrant: 'Techniques',
    description: `All exposed systems exposed on the internet must use strong encryption. For details, see the
      [Data Encryption Policy](https://enginet.wpengine.com/resources/security-policies/). This policy states minimum supported TLS versions.
      Use TLS, not the SSL Protocol.`,
    // last_updated: '2/4/2022',
    // last_reviewed: '2/18/2022',
  },
  {
    timeline: [
      {
        moved: MovedState.NoChange,
        ringId: 'Adopt',
        date: new Date('2022-08-05'),
      },
    ],
    url: '/docs/default/Component/user-centric-apis/',
    key: 'api-centric-uis',
    id: 'api-centric-uis',
    title: 'API-Centric UIs / User Centric APIs',
    quadrant: 'Techniques',
    description: `Recommended for new User Portal UIs and the APIs that support them. See documentation at
      [Pattern: API-Centric UIs calling User-Centric APIs](/docs/default/Component/user-centric-apis/).
      \nThe user-centric API pattern provides a method for building APIs that are called in the context of a user, typically a customer, who
      is logged into the portal or calling the Customer API. It does include impersonating a customer in the UI and calling APIs.
      \nThe user-centric API pattern is not presently intended for governing employee administrative access to APIs, separate from acting as
      a customer and the user portal. Use Google authentication or corporate Okta for company facing applications that require user
      authentication. We are evaluating the use of Okta tokens from our CIAM Okta environments (which link to corporate through SSO) for this
      purpose within PlatformOne, but the use case is presently experimental.
      \nThe user-centric API pattern is not intended for service-to-service communication.Use Google IAM or MTLS for service-to-service
      authentication. MTLS is recommended as the primary mechanism when calling Service to service within SDP. Google IAM is used for other
      cases, using Google service accounts to represent the service.`,
    // last_updated: '1/14/2022',
    // last_reviewed: '2/8/2022',
  },
  {
    timeline: [
      {
        moved: MovedState.NoChange,
        ringId: 'Adopt',
        date: new Date('2022-08-05'),
      },
    ],
    url: '#',
    key: 'jwt',
    id: 'jwt',
    title: 'Authentication: JWT',
    quadrant: 'Techniques',
    description: `Generally used within OAuth and Google IAM for access tokens. May be appropriate for other authentication. Do not use
      long-expiration JWTs to replace service-to-service authorization. Be sure to validate claims, and the expected encryption algorithm is
      the one used for creation and isn't one which allows forgery.
      \nReferences:
      [Auth0: Critical vulnerabilities in JSON Web Token libraries](https://auth0.com/blog/critical-vulnerabilities-in-json-web-token-libraries/)`,
    // last_updated: '2/8/2022',
    // last_reviewed: '2/8/2022',
  },
  {
    timeline: [
      {
        moved: MovedState.NoChange,
        ringId: 'Adopt',
        date: new Date('2022-08-05'),
      },
    ],
    url: '#',
    key: 'mutual-tls',
    id: 'mutual-tls',
    title: 'Authentication: mTLS',
    quadrant: 'Techniques',
    description: `Use mTLS for service-to-service authentication within Catalyst SDP where communication does not need to be internet
      facing. mTLS should be coupled with policies which control which service can talk to which service within SDP.
      \nmTLS is appropriate for other use cases as well.`,
    // last_updated: '1/26/2022',
    // last_reviewed: '2/8/2022',
  },
  {
    timeline: [
      {
        moved: MovedState.NoChange,
        ringId: 'Adopt',
        date: new Date('2022-08-05'),
      },
    ],
    url: '#',
    key: 'oauth',
    id: 'oauth',
    title: 'Authentication: OAuth2',
    quadrant: 'Techniques',
    // topic: 'Web Application Authentication',
    description: `Use Google authentication or corporate Okta for company facing applications that require user authentication, but no API
      tokens. We are evaluating the use of Okta tokens from our CIAM Okta environments (which link to corporate through SSO) for this purpose
      within PlatformOne.
      \nLeverage CIAM Okta for customer facing applications (e.g., user portal) which require user authentication/authorization. Hold for
      service authentication.
      \nUse Google IAM or mTLS for service-to-service authentication. mTLS is the primary mechanism when calling service-to-service within
      SDP. Google IAM is used for other cases, using Google service accounts to represent the client service. For example, use Google IAM when
      the source or target service are not in SDP.
      \nGitHub authentication is less-preferred because our GitHub organization membership is less strictly goverened than our corporate
      Okta groups.`,
    // last_updated: '1/26/2022',
    // last_reviewed: '2/11/2022',
  },
  {
    timeline: [
      {
        moved: MovedState.NoChange,
        ringId: 'Adopt',
        date: new Date('2022-08-05'),
      },
    ],
    url: '#',
    key: 'username-password-authentication',
    id: 'username-password-authentication',
    title: 'Authentication: User+Password',
    quadrant: 'Techniques',
    description: `Avoid creating new applications that have their own password authentication, because user management becomes unwieldy.
      Password authentication systems require constant vigil to keep up with the latest security standards (and are easy to get wrong).
      We prefer OAuth for customer-facing UIs, and Google IAM for service-to-service calls.`,
    // last_updated: '2/18/2022',
    // last_reviewed: '2/18/2022',
  },
  {
    timeline: [
      {
        moved: MovedState.NoChange,
        ringId: 'Adopt',
        date: new Date('2022-08-05'),
      },
    ],
    url: '#',
    key: 'aws-athena',
    id: 'aws-athena',
    title: 'Analytics: Amazon Athena',
    quadrant: 'Tools',
    description: `We have standardized on Google BigQuery for analytics and as a data warehouse. Never move data towards AWS/S3.
      Using Athena only for isolated use cases where complete data is already on S3, and where other teams and use cases do not need that
      data, is allowed. Making data available to BQ for present and future use cases, and building analytics there, is encouraged.`,
    // last_updated: '1/6/2022',
    // last_reviewed: '1/6/2022',
  },
  {
    timeline: [
      {
        moved: MovedState.NoChange,
        ringId: 'Adopt',
        date: new Date('2022-08-05'),
      },
    ],
    url: '#',
    key: 'bigquery',
    id: 'bigquery',
    title: 'Analytics: Google BigQuery',
    quadrant: 'Tools',
    description: `Google BigQuery is the strategic technology for our WP Engine data lake.`,
    // last_updated: '12/5/2016',
    // last_reviewed: '1/7/2022',
  },
  {
    timeline: [
      {
        moved: MovedState.Up,
        ringId: 'Adopt',
        date: new Date('2022-08-05'),
      },
    ],
    url: '#',
    key: 'argo',
    id: 'argo',
    title: 'CI/CD: Argo Workflow, CD',
    quadrant: 'Tools',
    description: `ArgoCD is used by both Flywheel and WP Engine for deploying versioned artifacts to Kubernetes at scale.  This is a
      vendor-agnostic alternative to Google Config Sync used by Catalyst SDP.
      \nUse Google Config Sync within Catalyst SDP for central services, where we are strategically aligning to Google vendor solutions.
      Argo Workflow and Argo CD is a vendor agnostic alternative for use within the fleet where components are deployed to multiple cloud
      providers. As an example, one might build container artifacts with Google Cloud Build and publish them to Google Container Registry,
      then deploy them to the fleet with Argo Workflow and CD, or deploy them centrally to Catalyst SDP using Google Config Sync.`,
    // last_updated: '1/7/2022',
    // last_reviewed: '8/5/2022',
  },
  {
    timeline: [
      {
        moved: MovedState.NoChange,
        ringId: 'Adopt',
        date: new Date('2022-08-05'),
      },
    ],
    url: '#',
    key: 'circle-ci',
    id: 'circle-ci',
    title: 'CI/CD: CircleCI',
    quadrant: 'Tools',
    description: `Use only for Open Source projects, Flywheel, StudioPress, Genesis, and WP Engine WebDev Marketing projects, but required in
      those instances, in that every project should have CI tooling.
      \nIf you are beginning a new project, consider Google Cloud Build.`,
    // last_updated: '3/4/2022',
    // last_reviewed: '3/7/2022',
  },
  {
    timeline: [
      {
        moved: MovedState.NoChange,
        description: 'Expanded use cases to include GitHub automation such as dependabot updates, and other low-frequency actions requiring minimal compute. No exceptions are required for such use cases.',
        ringId: 'Adopt',
        date: new Date('2024-04-26'),
      },
      {
        moved: MovedState.NoChange,
        ringId: 'Adopt',
        date: new Date('2022-08-25'),
      },
    ],
    url: '#',
    key: 'github-actions',
    id: 'github-actions',
    title: 'CI/CD: GitHub Actions',
    quadrant: 'Tools',
    description: `Be aware that Github Actions is a metered service, so usage should be limited. We have an allowance of 50,000 minutes per month which is
shared across the organization. A good rule of thumb is for each team to limit their usage of Actions to less than 1,000 minutes per month. If your usage is
significantly above that, please reach out to your manager to make sure we are budgeting for the additional usage cost.

GitHub Actions is approved for tasks such as running static analysis tools (like SonarQube or other linters) and GitHub automation such as
[Dependabot Minor Version Update Automation](https://wpengine.atlassian.net/wiki/spaces/CSI/pages/3599663110/Dependabot+Minor+Version+Update+Automation).
Actions is not recommended for compute-intensive tasks or for tasks that require elevated permissions, such as deployments.
For those tasks, we recommend using Google Cloud Build because it has better cost attribution and security controls.

When using Actions make sure you are familiar with
[Secure Configuration Baseline - GitHub Actions](https://docs.google.com/document/d/1s-hstYWL-6XX331XHnsZRCAANbhnX7HIw0sodkiYpRM/edit)
and
[GitHub's Security hardening for GitHub Actions](https://docs.github.com/en/actions/security-guides/security-hardening-for-github-actions).`,
    // last_updated: '4/26/2024',
    // last_reviewed: '4/26/2024',
  },
  {
    timeline: [
      {
        moved: MovedState.NoChange,
        ringId: 'Adopt',
        date: new Date('2022-08-05'),
      },
    ],
    url: '#',
    key: 'google-cloud-build',
    id: 'google-cloud-build',
    title: 'CI/CD: Google Clould Build',
    quadrant: 'Tools',
    description: `Required for SDP projects, encouraged generally.`,
    // last_updated: '1/6/2022',
    // last_reviewed: '1/7/2022',
  },
  {
    timeline: [
      {
        moved: MovedState.NoChange,
        ringId: 'Adopt',
        date: new Date('2022-08-05'),
      },
    ],
    url: '#',
    key: 'google-config-sync',
    id: 'google-config-sync',
    title: 'CI/CD: Google Config Sync',
    quadrant: 'Tools',
    description: `Google Config Sync is used within Catalyst SDP for deploying versioned artifacts to Kubernetes for central services,
      where we are strategically aligning to Google vendor solutions.
      \nArgo Workflow and Argo CD is a vendor agnostic alternative for use within the fleet where components are deployed to multiple cloud
      providers. As an example, one might build container artifacts with Google Cloud Build and publish them to Google Container Registry,
      then deploy them to the fleet with Argo Workflow and CD, or deploy them centrally to Catalyst SDP using Google Config Sync.`,
    // last_updated: '3/8/2022',
    // last_reviewed: '3/8/2022',
  },
  {
    timeline: [
      {
        moved: MovedState.NoChange,
        ringId: 'Adopt',
        date: new Date('2022-08-05'),
      },
    ],
    url: '#',
    key: 'helm',
    id: 'helm',
    title: 'CI/CD: Helm',
    quadrant: 'Tools',
    description: `Use as a package manager for Kubernetes.`,
    // last_updated: '10/16/2019',
    // last_reviewed: '2/25/2022',
  },
  {
    timeline: [
      {
        moved: MovedState.NoChange,
        ringId: 'Hold',
        date: new Date('2022-08-05'),
      },
    ],
    url: '#',
    key: 'jenkins',
    id: 'jenkins',
    title: 'CI/CD: Jenkins',
    quadrant: 'Tools',
    description: `We recommend Google Cloud Build for new projects. Jenkins is less maintained, and maintenance will end at some yet to be
      determined future date.
      \nSee:\n
      \n* [Secure Configuration Baseline  - Jenkins](https://docs.google.com/document/d/16eTCB9DTxmQi921-72kppboVxBeGjjiA2rIB3zFuUjY/edit)`,
    // last_updated: '12/5/2016',
    // last_reviewed: '1/7/2022',
  },
  {
    timeline: [
      {
        moved: MovedState.NoChange,
        ringId: 'Hold',
        date: new Date('2022-08-05'),
      },
    ],
    url: '#',
    key: 'jenkins-x',
    id: 'jenkins-x',
    title: 'CI/CD: JenkinsX',
    quadrant: 'Tools',
    description: `SPM is using JenkinsX in production. Further deployments are discouraged.`,
    // last_updated: '1/7/2022',
    // last_reviewed: '1/7/2022',
  },
  {
    timeline: [
      {
        moved: MovedState.NoChange,
        ringId: 'Prohibited',
        date: new Date('2022-08-05'),
      },
    ],
    url: '#',
    key: 'travis-ci',
    id: 'travis-ci',
    title: 'CI/CD: TravisCI',
    quadrant: 'Tools',
    description: `The business future for Travis is uncertain. We recommend Google Cloud Build.`,
    // last_updated: '2/14/2018',
    // last_reviewed: '1/7/2022',
  },
  {
    timeline: [
      {
        moved: MovedState.NoChange,
        ringId: 'Adopt',
        date: new Date('2022-08-05'),
      },
    ],
    url: '#',
    key: 'docker',
    id: 'docker',
    title: 'CI/CD: Docker',
    quadrant: 'Tools',
    description: `Includes containerization, Docker Desktop, etc. See the
      [Containerized Workloads Policy](https://drive.google.com/file/d/1Zvw2K8l_ZM0uRe2iskGa1pBRCoFrq7gG/view) and
      [Containerized Workloads Policy - Implementation Guidelines](https://drive.google.com/file/d/1Xw2fk7fwFLOrQElyjZ3FzGdMnx0zttqs/view).`,
    // last_updated: '2/1/2022',
    // last_reviewed: '2/1/2022',
  },
  {
    timeline: [
      {
        moved: MovedState.NoChange,
        ringId: 'Hold',
        date: new Date('2022-08-05'),
      },
    ],
    url: '#',
    key: 'vagrant',
    id: 'vagrant',
    title: 'CI/CD: Vagrant',
    quadrant: 'Tools',
    description: `Consider using Docker and Docker Compose instead of Vagrants for new services.
      \nVagrant (hobo-cm) is used as part of platform testing/development lifecycle.`,
    // last_updated: '10/16/2019',
    // last_reviewed: '2/25/2022',
  },
  {
    timeline: [
      {
        moved: MovedState.NoChange,
        ringId: 'Hold',
        date: new Date('2022-08-05'),
      },
    ],
    url: '#',
    key: 'ansible',
    id: 'ansible',
    title: 'CI/CD: Ansible',
    quadrant: 'Tools',
    description: `For services, PaaS (Heroku) and/or Containers are preferred for deployment and configuration. Our ansible environment has
      had challenges with testability and scalability.`,
    // last_updated: '12/5/2016',
    // last_reviewed: '1/7/2022',
  },
  {
    timeline: [
      {
        moved: MovedState.NoChange,
        ringId: 'Adopt',
        date: new Date('2022-08-05'),
      },
    ],
    url: '#',
    key: 'wordpress',
    id: 'wordpress',
    title: 'CMS: WordPress',
    quadrant: 'Tools',
    description: `See the
    [Secure Configuration Baseline - WordPress - Platform](https://docs.google.com/document/d/1ph-qGOeE5TULkfZOtpUJ20F1m7ExwTFCqvJ9khQbvZU/edit).`,
    // last_updated: '12/5/2016',
    // last_reviewed: '1/7/2022',
  },
  {
    timeline: [
      {
        moved: MovedState.NoChange,
        ringId: 'Adopt',
        date: new Date('2022-08-05'),
      },
    ],
    url: '#',
    key: 'grafana',
    id: 'grafana',
    title: 'Dashboards: Grafana',
    quadrant: 'Tools',
    description: `The strategic choice within Metrics Platform for dashboarding. Other use as appropriate is allowed by teams.`,
    // last_updated: '2/18/2022',
    // last_reviewed: '2/18/2022',
  },
  {
    timeline: [
      {
        moved: MovedState.NoChange,
        ringId: 'Adopt',
        date: new Date('2022-08-05'),
      },
    ],
    url: '/docs/default/Domain/customer-identity',
    key: 'okta',
    id: 'okta',
    title: 'Identity: Okta',
    quadrant: 'Tools',
    description: `Customer Identity and Access Management (CIAM) uses Okta as our OAuth service provider and directory. See the OAuth
      authentication technique for more. There are separate CIAM Okta instances for the development, staging, and production environments.
      The CIAM Okta environments are seprate from our corporate Okta instance where employee identities and groups are held, but CIAM
      environments federate employee authentication (SSO) to the corporate instance, and the corporate instance pushes groups to each CIAM
      environment.
      \nLeverage CIAM Okta for customer facing applications (e.g., user portal, Growth Suite) which require user authentication/authorization.
      Hold for service authentication. This allows SSO across our apps, so as more apps authenticate using Okta, the customer will not have
      to have so many credentials. Over time, we expect Growth Suite, Flywheel, customer use of Zendesk, Agency Partner Portal, abd possibly
      even Local authentication against Okta. Okta also supports SSO to our customers' identity providers, enabling customers to log in with
      their corporate credentials, and denying authentication when the user is disabled in the corporate identity system. Within these
      applications, Okta is the source of common user profile data, so if for example you change your email or last name from one app, the
      change is pushed to Okta so it affects all applications.
      \nAs our OAuth provider for users user-centric use cases, Okta issues access tokens for users. These access tokens are central to the
      pattern of API-Centric UIs / User Centric APIs. See that technique and its supporting documentation for more.
      \nAt this time, we recommend to use Google authentication or corporate Okta for company / employee facing applications that require
      user authentication, but no access tokens (no use of the User-Centric API pattern). We are evaluating the use of Okta tokens from our
      CIAM Okta environments (which link to corporate through SSO) for employee access purposea within the PlatformOne API, but this is
      experimental at this time.
      \nUse Google IAM or MTLS for service-to-service authentication when the service needs to be internet facing.
      \nIf you really want to get deep, the
      [Customer Authentication and Authorization North Star](/docs/default/Domain/customer-identity/ciam-north-star/)
      provides a vision of where the larger identity space is going, though it will take years to fully achieve the vision. Few outside of
      our identity team, Bouncer, should need to understand this detail.`,
    // last_updated: '2/23/2022',
    // last_reviewed: '2/25/2022',
  },
  {
    timeline: [
      {
        moved: MovedState.NoChange,
        ringId: 'Hold',
        date: new Date('2022-08-05'),
      },
    ],
    url: '#',
    key: 'aws-cloud-formation',
    id: 'aws-cloud-formation',
    title: 'CI/CD: AWS CloudFormation',
    quadrant: 'Tools',
    description: `Use Terraform except where resource types are not supported in Terraform (e.g., vendor-provided CF)`,
    // last_updated: '6/5/2019',
    // last_reviewed: '1/7/2022',
  },
  {
    timeline: [
      {
        moved: MovedState.NoChange,
        ringId: 'Adopt',
        date: new Date('2022-08-05'),
      },
    ],
    url: '#',
    key: 'terraform',
    id: 'terraform',
    title: 'CI/CD:Terraform',
    quadrant: 'Tools',
    description: `Used by Catalyst SDP and elsewhere for provisioning of cloud resources.`,
    // last_updated: '1/7/2022',
    // last_reviewed: '1/7/2022',
  },
  {
    timeline: [
      {
        moved: MovedState.NoChange,
        ringId: 'Required',
        date: new Date('2022-08-05'),
      },
    ],
    url: '#',
    key: 'jira',
    id: 'jira',
    title: 'Issues: Jira',
    quadrant: 'Tools',
    description: `Jira is our ticketing system.
    \nGenerally, Jira ticket references within pull requests are encouraged, and are required by most teams.
    \nSee:\n
    \n* [Jira Standardization](https://wpengine.atlassian.net/wiki/spaces/ProgramMgmt/pages/685115878/Jira+Standardization)
    \n* [Jira Administration](https://wpengine.atlassian.net/wiki/spaces/ProgramMgmt/pages/1195147392/Jira+Administration)`,
    // last_updated: '12/5/2016',
    // last_reviewed: '1/14/2022',
  },
  {
    timeline: [
      {
        moved: MovedState.NoChange,
        ringId: 'Hold',
        date: new Date('2022-08-05'),
      },
    ],
    url: '#',
    key: 'aws-secrets-manager',
    id: 'aws-secrets-manager',
    title: 'Secrets: AWS Secrets Manager',
    quadrant: 'Tools',
    description: `AWS Secrets manager should only be used for AWS services, not as a general secret manager. We prefer Cloud Build using
      Google Secrets Manager.`,
    // last_updated: '1/14/2022',
    // last_reviewed: '1/14/2022',
  },
  {
    timeline: [
      {
        moved: MovedState.NoChange,
        ringId: 'Hold',
        date: new Date('2022-08-05'),
      },
    ],
    url: '#',
    key: 'consul',
    id: 'consul',
    title: 'Consul',
    quadrant: 'Tools',
    description: `Hold, except for as a supporting technology for Vault.`,
    // last_updated: '6/30/2017',
    // last_reviewed: '1/14/2022',
  },
  {
    timeline: [
      {
        moved: MovedState.NoChange,
        ringId: 'Adopt',
        date: new Date('2022-08-05'),
      },
    ],
    url: '#',
    key: 'google-secrets-manager',
    id: 'google-secrets-manager',
    title: 'Secrets: GCP Secrets Manager',
    quadrant: 'Tools',
    description: `See
      [Secure Configuration Baseline - Google Secret Manager](https://docs.google.com/document/d/13WvOKFL-nBKGZGegeap98cjUgyE5gReoTsU0ZpprnYk/edit).`,
    // last_updated: '1/14/2022',
    // last_reviewed: '1/14/2022',
  },
  {
    timeline: [
      {
        moved: MovedState.NoChange,
        ringId: 'Hold',
        date: new Date('2022-08-05'),
      },
    ],
    url: '#',
    key: 'vault',
    id: 'vault',
    title: 'Secrets: Vault',
    quadrant: 'Tools',
    description: `We prefer Google Secrets Manager with Cloud Build, which is recommended for new services. Vault is less maintained than
      the GSM cloud service. Hold new services using Vault, but OK to add secrets if your service is already using Vault.`,
    // last_updated: '1/14/2022',
    // last_reviewed: '1/14/2022',
  },
  {
    timeline: [
      {
        moved: MovedState.NoChange,
        ringId: 'Adopt',
        date: new Date('2022-08-05'),
      },
    ],
    url: '#',
    key: 'aws-cloudwatch',
    id: 'aws-cloudwatch',
    title: 'Observe: AWS CloudWatch',
    quadrant: 'Tools',
    description: `Within AWS, there is very little downside to including CloudWatch monitoring. Services should replicate relevant
      logs/metrics into Metrics Platform/Datadog.
      \nSee the [Security Monitoring Policy](https://drive.google.com/file/d/1vm0W64TQ4hBdogtzfR05dC-tGSgD9E0o/view) and its guidance on
      logging.`,
    // last_updated: '6/30/2017',
    // last_reviewed: '1/14/2022',
  },
  {
    timeline: [
      {
        moved: MovedState.NoChange,
        ringId: 'Adopt',
        date: new Date('2022-08-05'),
      },
    ],
    url: '#',
    key: 'datadog',
    id: 'datadog',
    title: 'Observe: Datadog',
    quadrant: 'Tools',
    description: `Used historically to store logs, metrics, and dashboards for services (but not Farm). Do not to use Datadog synthetic
    monitoring, because it is not included in our current contract.
    \nSee the [Security Monitoring Policy](https://drive.google.com/file/d/1vm0W64TQ4hBdogtzfR05dC-tGSgD9E0o/view) and its guidance on
    logging.`,
    // last_updated: '1/14/2022',
    // last_reviewed: '1/14/2022',
  },
  {
    timeline: [
      {
        moved: MovedState.NoChange,
        ringId: 'Adopt',
        date: new Date('2022-08-05'),
      },
    ],
    url: '#',
    key: 'fluent',
    id: 'fluent',
    title: 'Observe: Fluent',
    quadrant: 'Tools',
    description: `Adopted for log routing and log agent (fluentd; fluentbit). Use caution when adding additional logs as performance can
      suffer.
      \nSee the [Security Monitoring Policy](https://drive.google.com/file/d/1vm0W64TQ4hBdogtzfR05dC-tGSgD9E0o/view) and its guidance on
      logging.`,
    // last_updated: '2/21/2018',
    // last_reviewed: '2/25/2022',
  },
  {
    timeline: [
      {
        moved: MovedState.NoChange,
        ringId: 'Adopt',
        date: new Date('2022-08-05'),
      },
    ],
    url: '#',
    key: 'google-cloud-ops',
    id: 'google-cloud-ops',
    title: 'Observe: GCP Cloud Ops',
    quadrant: 'Tools',
    description: `Google Cloud Operations is preferred for SDP deployments`,
    // last_updated: '1/14/2022',
    // last_reviewed: '1/14/2022',
  },
  {
    timeline: [
      {
        moved: MovedState.NoChange,
        ringId: 'Adopt',
        date: new Date('2022-08-05'),
      },
    ],
    url: 'https://github.com/wpengine/metrics-platform',
    key: 'metrics-platform',
    id: 'metrics-platform',
    title: 'Observe: Metrics Platform',
    quadrant: 'Tools',
    description: `[Metrics Platform](https://github.com/wpengine/metrics-platform) is an aggregate system which includes:\n
      \n* [a dashboard, based on Grafana](https://github.com/wpengine/metrics-platform-dashboard)
      \n* [a dataflow for metric processing within the stream](https://github.com/wpengine/metrics-platform-dataflow)
      \n* [an aggregation layer](https://github.com/wpengine/metrics-platform-aggregation-layer)
      \n* [a storage layer, including an InfluxDB time series database](https://github.com/wpengine/metrics-platform-storage-layer)
      \n* [alerting within data pipelines](https://github.com/wpengine/metrics-platform-alerting)
      \nUse the Metrics Platform for farm metrics. Looking forward, usage metrics driving subscription billing
      [will flow through the Metrics Pipeline](https://docs.google.com/document/d/1MTPP_sIWFThjys3mIaomp-Ecm_6nqLFM55fNiqkB6Jk/edit?pli=1#bookmark=id.n3fnrc7ttfid),
      so that the Mertics Platform becomes the single source of truth for general usage metrics, and eliminating prior per-metric custom
      flows. Other systems such as Web Vitals are also standardizing on the platform.
      \nSometimes the system has been alternately referred to as the Metrics Pipeline, given its dataflows, though Metrics Platform is
      preferred.`,
    // last_updated: '1/14/2022',
    // last_reviewed: '1/14/2022',
  },
  {
    timeline: [
      {
        moved: MovedState.NoChange,
        ringId: 'Adopt',
        date: new Date('2022-08-05'),
      },
    ],
    url: '#',
    key: 'new-relic',
    id: 'new-relic',
    title: 'Observe: New Relic',
    quadrant: 'Tools',
    description: `Stick to products within our corporate New Relic contract, such as APM and Synthetics. For services in Catalyst SDP, the
      use of its tracing and Google Operations Suite are encouraged as an alternative.`,
    // last_updated: '2/22/2022',
    // last_reviewed: '2/18/2022',
  },
  {
    timeline: [
      {
        moved: MovedState.NoChange,
        ringId: 'Adopt',
        date: new Date('2022-08-05'),
      },
    ],
    url: '#',
    key: 'open-telemetry',
    id: 'open-telemetry',
    title: 'Observe: OpenTelemetry',
    quadrant: 'Tools',
    description: `For Catalyst SDP applications, use OpenTelemetry libraries to propagate trace contexts, and when you want to generate
      trace spans beyond what is provided natively by the Anthos Service Mesh.
      [How-To: Configure Tracing](/docs/default/system/catalyst-software-delivery-platform/guides/app-observability/configure-tracing/).`,
    // last_updated: '8/4/2022',
    // last_reviewed: '2/18/2022',
  },
  {
    timeline: [
      {
        moved: MovedState.NoChange,
        ringId: 'Prohibited',
        date: new Date('2022-08-05'),
      },
    ],
    url: '#',
    key: 'papertrail',
    id: 'papertrail',
    title: 'Observe: Papertrail',
    quadrant: 'Tools',
    description: `Move to other monitoring strategies.`,
    // last_updated: '1/14/2022',
    // last_reviewed: '1/14/2022',
  },
  {
    timeline: [
      {
        moved: MovedState.NoChange,
        ringId: 'Hold',
        date: new Date('2022-08-05'),
      },
    ],
    url: '#',
    key: 'prometheus',
    id: 'prometheus',
    title: 'Observe: Prometheus',
    quadrant: 'Tools',
    description: `Use Datadog for metrics; Prometheus interfaces are adopted for Datadog scraping. Used internally to SDP.`,
    // last_updated: '6/5/2019',
    // last_reviewed: '1/14/2022',
  },
  {
    timeline: [
      {
        moved: MovedState.NoChange,
        ringId: 'Adopt',
        date: new Date('2022-08-05'),
      },
    ],
    url: '/docs/default/system/user-portal/Rollbar-logging-managment/',
    key: 'rollbar',
    id: 'rollbar',
    title: 'Observe: Rollbar',
    quadrant: 'Tools',
    description: `Logging may prove sufficient for many services. Where error monitoring and alerting is needed beyond logging, Rollbar
      has been our standard.
      \nUse for error monitoring and alerting. Use Rollbar only for tracking of unexpected exceptions that require human attention.
      Only exceptional, actionable events should be sent to Rollbar. Exceptions handled in code, or which are expected, should be logged
      only. For example, in the User Portal, we provide [this guidance](/docs/default/system/user-portal/Rollbar-logging-managment/).
      \nOne of the ways we can improve monitoring Rollbar logs is to improve the grouping of the messages. Rollbar attempts to group the
      messages appropriately and does a pretty good first pass. It tends to be conservative so we do not lose distinct messages in
      inappropriate groups. The docs, [Grouping Occurrences into Items](https://docs.rollbar.com/docs/grouping-occurrences) show how the
      automated grouping can be made better with manual grouping and by using the notion of custom fingerprinting.
      \nRollbars have a cost, and are generally best suited to teams that actively monitor for exceptions.`,
    // last_updated: '2/23/2022',
    // last_reviewed: '1/14/2022',
  },
  {
    timeline: [
      {
        moved: MovedState.NoChange,
        ringId: 'Hold',
        date: new Date('2022-08-05'),
      },
    ],
    url: '#',
    key: 'zabbix',
    id: 'zabbix',
    title: 'Observe: Zabbix',
    quadrant: 'Tools',
    description: `Metrics Platform is preferred.
      \nStill used by cron servers, CMDB, platform APIs, Overdrive. Zabbix has known scaling issues. We would like to reduce use cases
      over time.`,
    // last_updated: '2/1/2022',
    // last_reviewed: '2/1/2022',
  },
  {
    timeline: [
      {
        moved: MovedState.Up,
        ringId: 'Evaluate',
        date: new Date('2022-08-05'),
      },
    ],
    url: '#',
    key: 'google-cloud-workflow',
    id: 'google-cloud-workflow',
    title: 'Orchestration: GCP Workflow',
    quadrant: 'Tools',
    description: `Google Cloud Workflow is encouraged for use in combination with central services to orchestrate process across one or
      more services, or jobs for a single service, to externalize your workflow definition.
      \nPresently being evaluated by the Storefront team. The POC went well, and the project is moving towards production deployment.`,
    // last_updated: '8/1/2022',
    // last_reviewed: '8/5/2022',
  },
  {
    timeline: [
      {
        moved: MovedState.NoChange,
        ringId: 'Hold',
        date: new Date('2022-08-05'),
      },
    ],
    url: '#',
    key: 'aws-sqs',
    id: 'aws-sqs',
    title: 'Queue: Amazon SQS',
    quadrant: 'Tools',
    description: `We prefer Google Pub/Sub where possible.`,
    // last_updated: '2/18/2022',
    // last_reviewed: '2/18/2022',
  },
  {
    timeline: [
      {
        moved: MovedState.NoChange,
        ringId: 'Adopt',
        date: new Date('2022-08-05'),
      },
    ],
    url: '#',
    key: 'google-pubsub',
    id: 'google-pubsub',
    title: 'Queue: Google Pub/Sub',
    quadrant: 'Tools',
    description: `We prefer Google Pub/Sub where possible over other messaging and queueing technologies, except when messaging traffic is
      between farm elements on some provider. Dataflows within our metrics platform standardize on Pub/Sub.
      \nThe
      [Guide: GCP Pub/Sub Best Practices](/docs/default/system/catalyst-software-delivery-platform/reference/gcp-pub-sub-best-practices/)
      document provides guidance to teams on using PubSub, especially with Catalyst SDP.
      \nConsider whether Google Cloud Tasks may be appropriate for your use case.`,
    // last_updated: '8/5/2022',
    // last_reviewed: '2/25/2022',
  },
  {
    timeline: [
      {
        moved: MovedState.Up,
        ringId: 'Adopt',
        date: new Date('2022-08-05'),
        description: 'Because Google Cloud Tasks is successfully used across teams, it is entering the radar in Adopt.'
      },
    ],
    url: '#',
    key: 'google-cloud-tasks',
    id: 'google-cloud-tasks',
    title: 'Queue: Google Cloud Tasks',
    quadrant: 'Tools',
    description: `[Google Cloud Tasks](https://cloud.google.com/tasks/docs/dual-overview) has proven to accelerate teams that need simple
      task queues. Cloud Tasks works as a queue that makes HTTP requests to an endpoint defined in the Task definition. It has
      configurations for scheduling and exponential backoff. Cloud Scheduler can natively create Tasks.
      While it is possible to build something similar using Pub/Sub and SDP containers, Cloud Functions, or Cloud Run;
      Cloud Tasks can simplify the process and reduce complexity, infrastructure and operations burden. Google provides a document to help
      [choose between Cloud Tasks and Pub/Sub](https://cloud.google.com/tasks/docs/comp-pub-sub). The approach has been successfully
      used by
      Webstack ([wren-api](https://github.com/wpengine/wren-api/blob/main/deploy/terraform/prod/main.tf#L121)) and
      Ares ([wpe-payment-service](https://github.com/wpengine/wpe-payments-service/pull/85/files)).
      Note that Bouncer previously used Cloud Tasks in
      [CIAM](https://backstage.wpesvc.net/catalog/default/component/ciam/decisions?record=0008-background_processing.md), but later
      [superceded that decision](https://backstage.wpesvc.net/catalog/default/component/ciam/decisions?record=0011-background_processing.md)
      given timeouts from long-running tasks. Consider whether your tasks will complete quickly without SDP timeouts when considering your
      design.
      \n[This Slack thread](https://wpengine.slack.com/archives/GFF7QRJ0L/p1658154447257399) may be helpful.`,
  },
  {
    timeline: [
      {
        moved: MovedState.NoChange,
        ringId: 'Hold',
        date: new Date('2022-08-05'),
      },
    ],
    url: '#',
    key: 'rabbit-mq',
    id: 'rabbit-mq',
    title: 'Queue: RabbitMQ',
    quadrant: 'Tools',
    description: `We prefer cloud provider managed services like Google PubSub to independent SaaS offerings.
    \nUsed for the Migration service and BAPI.`,
    // last_updated: '2/28/2018',
    // last_reviewed: '2/25/2022',
  },
  {
    timeline: [
      {
        moved: MovedState.NoChange,
        ringId: 'Adopt',
        date: new Date('2022-08-05'),
      },
    ],
    url: '#',
    key: 'postman',
    id: 'postman',
    title: 'APIs: Postman',
    quadrant: 'Tools',
    description: `Postman is used for API testing, and can be especially useful in logging in with OAuth to obtain user tokens.
      Many users can take advantage of a free account, however we also have paid seats for those who want to use collaboration features.
      Be diligent about not storing secrets in shared collections, synced to the Postman cloud.
      \nPostman can be useful in autheticating with OAuth to obtain a user token, when testing user-centric APIs. See:
      \n* [Create an Okta OAuth 2 access token and use it in Postman for testing API calls](/docs/default/system/user-portal/Create-an-Okta-OAuth-2-access-token-and-use-it-in-Postman-for-testing-API-calls/)
      \n* [Guide: Developing User-Centric APIs in Services that support the API-Centric Pattern](/docs/default/component/user-centric-apis/Guide_Developing_User-Centric_APIs/)`,
    // last_updated: '10/28/21',
    // last_reviewed: '2/12/22',
  },
  {
    timeline: [
      {
        moved: MovedState.NoChange,
        ringId: 'Adopt',
        date: new Date('2024-04-10'),
      },
    ],
    url: '#',
    key: 'split',
    id: 'split',
    title: 'Feature Flags: Split.io',
    quadrant: 'Tools',
    description: `Split.io is a software platform designed to help engineering and product teams manage the rollout of
      new features within their applications using Feature Flags and A/B testing experimentation.
      \n [Guide: Feature flags with Split.io](https://backstage.wpesvc.net/docs/default/system/user-portal/Feature-Flags-with-Split.io/)`,
  },
];
